import React, { useState } from "react";
import style from "./style.module.scss";
import { Row, Col } from "antd";
import { StoreImages } from "../../Storeimgaes/StoreImages";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { useEffect } from "react";
import { getBalanceHistory } from "../../../redux/services";
import { dateToUtc, preciseData, truncateData2, uppercase } from "../../../helpers";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { NoRecord } from "../../NoRecord";
import CustomPagination from "../../customHooks/customPagination";
const initData = {
  page: 1,
  limit: 10,
};
const PrimaryBalanceTab = () => {
  const { t } = useTranslation();
  const { flow, downarrows } = StoreImages;
  const { id } = useParams();
  const dispatch = useDispatch();
  const { balanceList } = useSelector((state) => state.commonApiData);
  const { balanceHistoryTotal, balanceHistory } = useSelector(
    (state) => state.commonApiData
  );
  const [currentBalance, setCurrentBalance] = useState({});
  const [paginationState, setPaginationState] = useState({ ...initData });

  const { page, limit } = paginationState;

  const apiCall = (data) => {
    dispatch(
      getBalanceHistory({
        limit: limit,
        page: page,
        currency_id: id,
        ...data,
      })
    );
  };

  useEffect(() => {
    apiCall({});
  }, []);

  const filterBalance = (id) => {
    let filterData = balanceList?.find?.((val, idx) => val.currency === id);
    setCurrentBalance(filterData);
    return filterData;
  };

  useEffect(() => {
    if (balanceList.length) {
      filterBalance(id);
    }
  }, [balanceList]);

  const balance = Number(currentBalance?.balance ?? 0);
  const locked = Number(currentBalance?.locked ?? 0);
  const usdt_value = Number(currentBalance?.currency_usdt ?? 0);
  const currency = currentBalance?.currency ?? "";
  const balanceString = `${(balance + locked)
    .toFixed(2)
    .replace(/\.0+$/, "")} ${uppercase(currency)} ($${(
      (balance + locked) *
      usdt_value
    )
      .toFixed(2)
      .replace(/\.0+$/, "")})`;

  return (
    <>
      <div className={`${style.cointdetailPage_tabs_balance} ${style.mt} `}>
        <h4>
          {t("portfolio.balance")}: <span>{balanceString}</span>
        </h4>

        <div className={style.scrolledEleMob}>
          {balanceHistory?.length > 0 ? (
            balanceHistory?.map((item, index) => {
              let currUpercase = uppercase(item?.currency);
              let fiatcurrUpercase = uppercase(item?.fait_currency);

              let isSwapP2PBal = item.reference_kind === "swap_p2p_balance";
              let prevBal = Number(item.previous_balance);
              let newBal = Number(item?.new_balance);
              let prevSubNewUsdt = (prevBal - newBal) * usdt_value;
              let newSubPrevUsdt = (newBal - prevBal) * usdt_value;
              let pair = item?.reference_type.split(":")[2];
              return (
                <Row>
                  <Col xl={24}>
                    <div key={index} className={style.primarybal}>
                      <div className={style.primarybal_left}>
                        <p>
                          {/* {moment(item?.created_at).format("MMM")}{" "} */}
                          {dateToUtc(item?.created_at, "MMM")}{" "}
                          {/* <span>{moment(item?.created_at).format("DD")}</span> */}
                          <span>{dateToUtc(item?.created_at, "DD")}</span>
                        </p>
                        <img src={flow} alt="icon" />
                        <p>
                          {item?.reference_type === "transakpayment" ? (
                            <>
                              <span>
                                {item?.transak_type === "SELL"
                                  ? `Sell ${currUpercase} to receive ${fiatcurrUpercase}`
                                  : `Buy ${currUpercase} with ${fiatcurrUpercase}`}
                              </span>
                              Using {currUpercase} Wallet
                            </>
                          ) : item?.reference_type === "deposit" ? (
                            <>
                              <span>{`Received ${currUpercase}`}</span>
                              From {currUpercase} Deposit
                            </>
                          ) : item?.reference_type === "withdraw" ? (
                            <>
                              <span>{`Sent ${currUpercase}`}</span>
                              To {currUpercase} Address
                            </>
                          ) : item?.reference_type === "adjustment" ? (
                            <>
                              <span>{`Sent ${currUpercase}`}</span>
                              To {currUpercase}
                            </>
                          ) : item?.reference_type === "trade" ? (
                            <>
                              <span>{`Trade ${currUpercase}`}</span>
                              Using pair {currUpercase}
                            </>
                          ) : item?.reference_type.includes("order") ? (
                            <>
                              <span>Order</span>
                              Using pair {uppercase(pair)}
                            </>
                          ) : item?.reference_type === "account" &&
                            item?.reference_kind == "transfer_to_gridbot" ? (
                            <>
                              <span>{`Transfer to  Grid Bot wallet
                            `}</span>
                              from {currUpercase} Wallet
                            </>
                          ) : item?.reference_type === "account" &&
                            item?.reference_kind == "transfer_from_gridbot" ? (
                            <>
                              <span>{`Transfer from  Grid Bot wallet
                            `}</span>
                              to {currUpercase} Wallet
                            </>
                          ) : item?.reference_type === "account" ? (
                            <>
                              <span>{`Transfer from ${currUpercase}
                            `}</span>
                              Using {currUpercase} Wallet
                            </>
                          ) : item?.reference_type === "redeemreward" ? (
                            <>
                              <span>{`Redeem Reward ${currUpercase}
                            `}</span>
                              in {currUpercase} Wallet
                            </>
                          ) : null}
                        </p>
                      </div>
                      <div className={style.primarybal_right}>
                        <p className={style.baseblue}>
                          {item?.reference_type === "account" && isSwapP2PBal
                            ? truncateData2(
                              item?.previous_balance - item?.new_balance,
                              item.precision
                            )
                            : truncateData2(
                              item?.new_balance - item?.previous_balance,
                              item.precision
                            )}{" "}
                          {currUpercase}
                        </p>
                        <p>
                          $
                          {item?.reference_type === "account" && isSwapP2PBal
                            ? truncateData2(prevSubNewUsdt, 2)
                            : truncateData2(newSubPrevUsdt, 2)}
                        </p>
                      </div>
                    </div>
                  </Col>
                </Row>
              );
            })
          ) : (
            <div className="empty_data">
              <NoRecord />
            </div>
          )}
        </div>
        <CustomPagination
          pageNo={page}
          limit={limit}
          total={balanceHistoryTotal}
          onChange={(pageNo, pageSize) => {
            apiCall({ page: pageNo, limit: pageSize });
            setPaginationState({
              ...paginationState,
              page: pageNo,
              limit: pageSize,
            });
          }}
        />
      </div>
    </>
  );
};

export default PrimaryBalanceTab;
