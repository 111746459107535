import React from "react";
import { StoreImages } from "../../Storeimgaes/StoreImages";
import { useSelector } from "react-redux";

import style from "./style.module.scss";
import { uppercase } from "../../../helpers";
import HeadComman from "../../Common/HeadComman";

const NetworkList = (props) => {
  const { onClick, networkList, cbFun } = props;

  return (
    <>
      <div className={`card ${style.btnList}`}>
      <HeadComman title="Network" onClick={onClick} />
        <ul>
          {networkList.length > 0 && networkList.map((val, id) => (
            <>
              <li id={id} onClick={() => cbFun(val, "network")}>
                <h6>
                  {uppercase(val.network_name)}
                  <br />
                  <span>{uppercase(val.transak_network)}</span>
                </h6>
              </li>
            </>
          ))}
        </ul>
      </div>
    </>
  );
};

export default NetworkList;
