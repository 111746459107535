export const getKycStatus = (kycLabels) => {
  let status = "Verify";
  let description =
    "Verify Identify documents for advanced amount to send and receiving option";
  kycLabels.length > 0 &&
    kycLabels.map((label) => {
      if (
        label.scope === "private" &&
        label.key === "basic-kyc-level" &&
        label.value === "verified"
      ) {
        description = label.description;
        status = "Verified";
      } else if (
        label.scope === "private" &&
        label.key === "basic-kyc-level" &&
        label.value === "initiated"
      ) {
        status = "Initiated";
        description = label.description;
      }else if (
        label.scope === "private" &&
        label.key === "basic-kyc-level" &&
        label.value === "invalid"
      ) {
        status = "Invalid";
        description = label.description;
      }else if (
        label.scope === "private" &&
        label.key === "basic-kyc-level" &&
        label.value === "cancelled"
      ) {
        status = "Cancelled";
        description = label.description;
      }else if (
        label.scope === "private" &&
        label.key === "basic-kyc-level" &&
        label.value === "unauthorized"
      ) {
        status = "Unauthorized";
        description = label.description;
      }else if (
        label.scope === "private" &&
        label.key === "basic-kyc-level" &&
        label.value === "rejected"
      ) {
        status = "Rejected";
        description = label.description;
      }else if (
        label.scope === "private" &&
        label.key === "basic-kyc-level" &&
        label.value === "onhold"
      ) {
        status = "Onhold";
        description = label.description;
      }else if (
        label.scope === "private" &&
        label.key === "basic-kyc-level" &&
        label.value === "deleted"
      ) {
        status = "Deleted";
        description = label.description;
      }
    });
  return { status, description };
};
