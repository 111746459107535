import { createSlice } from "@reduxjs/toolkit";
import {
  generateQrCode,
  getBalance,
  getBalanceHistory,
  getCurrencies,
  getCurrencyDetails,
  getGainersLosers,
  getGraphData,
  getPortfolioPrice,
  getSingleCurrencyDetails,
} from "../../services";
export const commonSlice = createSlice({
  name: "commonSlice",
  initialState: {
    balanceList: [],
    currenciesList: [],
    error: false,
    currencyDetails: [],
    balanceHistory: [],
    balanceHistoryTotal: 0,
    singleCurrency: {},
    getCode: "",
    clickViewFrom: "",
    currentMarketId: "",
    gainersLosers: [],
    currencyGraphData: [],
    portfolioPrices: {},
    phoneSubmited: false,
    timeFrame: 'hr'
  },
  reducers: {
    viewForTrans: (state, action) => {
      state.clickViewFrom = action?.payload?.clickViewFrom;
      state.currentMarketId = action?.payload?.marketId;
    },
    phoneSubmited: (state, action) => {
      state.phoneSubmited = action.payload;
    },
    graphTimeFrame: (state, action) => {
      state.timeFrame = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getBalance.pending, (state) => {
        state.error = false;
      })
      .addCase(getBalance.fulfilled, (state, action) => {
        state.loading = false;
        state.balanceList = action.payload.list;
      })
      .addCase(getBalance.rejected, (state, action) => {
        state.error = true;
      })
      .addCase(getCurrencies.pending, (state) => {
        state.error = false;
      })
      .addCase(getCurrencies.fulfilled, (state, action) => {
        state.loading = false;
        state.currenciesList = action.payload.list;
      })
      .addCase(getCurrencies.rejected, (state, action) => {
        state.error = true;
      })
      .addCase(getCurrencyDetails.pending, (state) => {
        state.error = false;
      })
      .addCase(getCurrencyDetails.fulfilled, (state, action) => {
        state.loading = false;
        state.currencyDetails = action.payload.list;
      })
      .addCase(getCurrencyDetails.rejected, (state) => {
        state.error = true;
      })

      .addCase(getGraphData.pending, (state) => { })
      .addCase(getGraphData.fulfilled, (state, action) => {
        state.currencyGraphData = action.payload.list;
      })
      .addCase(getGraphData.rejected, (state) => { })

      .addCase(getSingleCurrencyDetails.pending, (state) => {
        state.error = false;
      })
      .addCase(getSingleCurrencyDetails.fulfilled, (state, action) => {
        state.loading = false;
        state.singleCurrency = action.payload;
      })
      .addCase(getSingleCurrencyDetails.rejected, (state) => {
        state.error = true;
      })

      .addCase(getBalanceHistory.pending, (state) => {
        state.error = false;
      })
      .addCase(getBalanceHistory.fulfilled, (state, action) => {
        state.loading = false;
        state.balanceHistory = action.payload.data;
        state.balanceHistoryTotal = action.payload.total || 0;
      })
      .addCase(getBalanceHistory.rejected, (state) => {
        state.error = true;
      })

      .addCase(generateQrCode.pending, (state) => {
        state.error = false;
      })
      .addCase(generateQrCode.fulfilled, (state, action) => {
        state.loading = false;
        state.getCode = action.payload.qrCode;
      })
      .addCase(generateQrCode.rejected, (state) => {
        state.error = true;
      })

      .addCase(getGainersLosers.pending, (state) => { })
      .addCase(getGainersLosers.fulfilled, (state, action) => {
        state.gainersLosers = action.payload.data;
      })
      .addCase(getGainersLosers.rejected, (state) => { })

      .addCase(getPortfolioPrice.pending, (state) => { })
      .addCase(getPortfolioPrice.fulfilled, (state, action) => {
        state.portfolioPrices = action.payload;
      })
      .addCase(getPortfolioPrice.rejected, (state) => { });
  },
});

export const { viewForTrans, graphTimeFrame, phoneSubmited } = commonSlice.actions;
export default commonSlice.reducer;
