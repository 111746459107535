import React from "react";
import style from "./style.module.scss";
import CommonButton from "../../Ui/button/CommonButton";
import { InfoCircleOutlined } from "@ant-design/icons";
import { Trans, useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { getKycUrl } from "../../../redux/services";
import { useNavigate } from "react-router-dom";
import { checkProfile } from "../../../helpers/checkProfile";
import { isLevel3 } from "../../../helpers";
import { getKycStatus } from "../../../helpers/getKycStatus";
function Identityverify({ closeModal }) {
  const { data } = useSelector((state) => state?.user);

  const { level, labels } = data;

  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const redirectToKyc = async () => {
    const val = await checkProfile(data, navigate, dispatch, closeModal);
    if (val) {
      const res = await dispatch(getKycUrl());
      if (res.payload.status) {
        navigate("/kyc");
      }
    }
  };

  const { status, description } = labels && getKycStatus(labels);


  return (
    <div className={style.identity}>
      <div className={`${style.identitydata} kycModal`}>
        <InfoCircleOutlined />
        <h2>{t("identity_vefification")}</h2>
        <p>
          {status === "Rejected" ? description : <Trans i18nKey="identity_description" />}
        </p>
        <div className="submitBtn_theme">
          <CommonButton
            title={t("buttons.submit_id")}
            className="btn cutm_clr_mod"
            onClick={redirectToKyc}
            disabled={isLevel3(level) || status === "Rejected"}
          />
        </div>
      </div>
    </div>
  );
}
export default Identityverify;
