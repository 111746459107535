import { createSlice } from "@reduxjs/toolkit";
import { getNotifications } from "../../services/notificationService";
export const notificationSlice = createSlice({
  name: "notification",
  initialState: {
    list: [],
    total: "",
    notiList: [],
    error: false,
    readNotiId: {},
  },
  reducers: {
    // insertNotificationList: (state, action) => {
    //   state.notiList = [action.payload, ...state.notiList];
    //   state.list = [action.payload, ...state.list];
    // },

    appendNewMessage: (state, action) => {
      state.notiList = [...state.notiList, action.payload];
      state.list = [action.payload, ...state.list];
    },

    insertNotificationList: (state, action) => {
      state.notiList = [action.payload, ...state.notiList];
      let aboutCheck = state.notiList[0].about
      if ((
        aboutCheck === "cancelled" ||
        aboutCheck === "unresolved" ||
        aboutCheck === "resolved") ||
        state.notiList[0]?.notifiable_type === "Appeal" && state.notiList[0]?.event !== "admin_message"
        && state.notiList[0]?.event !== "admin_action"
      ) {
      }
      else {
        state.list = [action.payload, ...state.list];
      }
    },

    readNotificationIds: (state, action) => {
      state.readNotiId = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getNotifications.pending, (state) => {
        state.error = false;
      })
      .addCase(getNotifications.fulfilled, (state, action) => {
        state.list = action.payload.list;
        state.total = action.payload.total;
      })
      .addCase(getNotifications.rejected, (state) => {
        state.error = true;
      });
  },
});
export default notificationSlice.reducer;
export const { insertNotificationList, appendNewMessage, readNotificationIds } =
  notificationSlice.actions;
