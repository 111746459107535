import { Tabs, message } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { createPairList, isLevel3 } from "../../../helpers";
import { viewForTrans } from "../../../redux/feature";
import {
  getBalanceHistory,
  getDepositHistory,
  getMarketList,
  getTrades,
  getTransHisTrades,
  getWithdrawHistory,
} from "../../../redux/services";
import { fetchBuySellHistory } from "../../../redux/services/buySellServices";
import {
  exchangeOrdersTabs,
  settingHisotryTabsKey,
} from "../../../staticObjects";
import { ReactComponent as FilterIcon } from "../../Assets/Images/filter.svg";
import Orders from "./Orders";
import {
  Buy,
  Receive,
  Sell,
  Send,
  Trades,
  TransferHistory,
} from "./TransHistoryComponents";
import style from "./style.module.scss";

import Export from "../../Assets/Images/export.svg";
import { setFilter } from "../../../redux/feature/exchange/exchanges.slice";

const TransactionsHistory = ({ state }) => {
  const { TabPane } = Tabs;
  const dispatch = useDispatch();
  const { clickViewFrom } = useSelector((state) => state?.commonApiData);
  const { data } = useSelector((state) => state?.user);
  const { level } = data;

  const { marketPairList } = useSelector((state) => state.transactionHistories);
  const { currentMarketId } = useSelector((state) => state.commonApiData);
  const [isFilterVisible, setIsFilterVisible] = useState(true);
  const [isExportVisible, setIsExportVisible] = useState(false);

  const toggleFilter = () => {
    setIsFilterVisible(!isFilterVisible);
    setIsExportVisible(false);
  };

  const toggleExport = () => {
    if (!isLevel3(level)) {
      message.error("Please complete your KYC verfication");
      return;
    }
    setIsExportVisible(!isExportVisible);
    setIsFilterVisible(false);
  };

  const { buy, sell, send, receive, orders, trades, transfer } =
    settingHisotryTabsKey;
  const { openOrder, allOrder, tradeHistory } = exchangeOrdersTabs;

  const [currentTab, setCurrentTab] = useState(buy);

  const commProp = {
    isFilterVisible: isFilterVisible,
    isExportVisible: isExportVisible,
  };

  useEffect(() => {
    if ([openOrder, allOrder].includes(clickViewFrom)) {
      setCurrentTab(orders);
    }
    if ([tradeHistory].includes(clickViewFrom)) {
      if (!marketPairList.length) return;
      setCurrentTab(trades);
      hitApi(trades);
      return;
    }
    return () => {
      dispatch(viewForTrans({ clickViewFrom: "", marketId: "" }));
    };
  }, [clickViewFrom]);

  const tabsObj = [
    {
      tab: "Buy",
      keys: buy,
      component: currentTab === buy && (
        <div className={style.buyTable}>
          <Buy {...commProp} />
        </div>
      ),
    },
    {
      tab: "Sell",
      keys: sell,
      component: currentTab === sell && (
        <div className={style.buyTable}>
          <Sell {...commProp} />
        </div>
      ),
    },
    {
      tab: "Send",
      keys: send,
      component: currentTab === send && (
        <div className={style.sendTable}>
          <Send {...commProp} />{" "}
        </div>
      ),
    },
    {
      tab: "Receive",
      keys: receive,
      component: currentTab === receive && (
        <div className={style.sendTable}>
          <Receive {...commProp} />
        </div>
      ),
    },
    {
      tab: "Orders",
      keys: orders,
      component: currentTab === orders && (
        <div className={style.orderTable}>
          <Orders {...commProp} />
        </div>
      ),
    },
    {
      tab: "Trades",
      keys: trades,
      component: currentTab === trades && (
        <div className={style.tradeTable}>
          <Trades {...commProp} />
        </div>
      ),
    },
    {
      tab: "Transfer",
      keys: transfer,
      component: currentTab === transfer && (
        <div className={style.tradeTable}>
          <TransferHistory {...commProp} />
        </div>
      ),
    },
  ];

  const hitApi = (tabName) => {
    const sendData = {
      limit: 10,
      page: 1,
    };
    switch (tabName) {
      case buy:
        sendData["transak_type"] = "BUY";
        dispatch(fetchBuySellHistory({ ...sendData }));
        break;
      case sell:
        sendData["transak_type"] = "SELL";

        dispatch(fetchBuySellHistory({ ...sendData }));

        break;
      case send:
        dispatch(getWithdrawHistory({ ...sendData }));
        break;
      case receive:
        dispatch(getDepositHistory({ ...sendData }));
        break;
      case trades:
        if (!marketPairList.length) return;
        const pairList = createPairList(marketPairList);
        let toSendMarket = currentMarketId
          ? currentMarketId
          : pairList[0].value;
        // dispatch(getTrades({ ...sendData, market: toSendMarket }));
        dispatch(getTransHisTrades({ ...sendData, market: toSendMarket }));
        break;
      case transfer:
        dispatch(
          getBalanceHistory({
            ...sendData,
            reference_type: "Account",
          })
        );
        break;
      default:
    }
  };

  useEffect(() => {
    hitApi(currentTab);
    dispatch(getMarketList());
  }, []);

  useEffect(() => {
    if ([openOrder, allOrder].includes(state)) {
      setCurrentTab(orders);
      return;
    }
    if ([tradeHistory].includes(state)) {
      if (!marketPairList.length) return;
      setCurrentTab(trades);
      hitApi(trades);
      return;
    }
  }, [state, marketPairList]);

  const onTabChage = (tabname) => {
    setCurrentTab(tabname);
    dispatch(setFilter({}));
    hitApi(tabname);
    setIsFilterVisible(true);
    setIsExportVisible(false);
    dispatch(viewForTrans({ clickViewFrom: "", marketId: "" }));
  };

  let isShowExport = [
    buy,
    sell,
    send,
    receive,
    orders,
    trades,
    transfer,
  ].includes(currentTab);
  let isShowFilter = [
    buy,
    sell,
    send,
    receive,
    orders,
    trades,
    transfer,
  ].includes(currentTab);

  return (
    <div className="textDecoration">
      <div className="filter_tabs filer">
        {isShowExport && (
          <p
            title="Export"
            onClick={toggleExport}
            className={isExportVisible ? "exportBtn" : "exportBtn"}
          >
            <img src={Export} alt="" />
            Export
          </p>
        )}
        {isShowFilter && (
          <FilterIcon
            onClick={toggleFilter}
            title="Filter"
            className={isFilterVisible ? "active" : ""}
          />
        )}
      </div>

      <Tabs
        className="transactionHistory"
        onChange={onTabChage}
        defaultActiveKey={currentTab}
        activeKey={currentTab}
        animated
      >
        <>
          {tabsObj?.map((item, idx) => {
            return (
              <TabPane tab={item.tab} key={item.keys}>
                {item.component}
              </TabPane>
            );
          })}
        </>
      </Tabs>
    </div>
  );
};

export default TransactionsHistory;
