import { createSlice } from "@reduxjs/toolkit";
import { getKycUrl } from "../../services";

export const kycSlice = createSlice({
  name: "kyc",
  initialState: {
    loading: false,
    url: "",
  },
  reducers: {
    resetUrl: (state) => {
      state.url = "";
      state.loading = false
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getKycUrl.pending, (state) => {
        state.loading = true;
      })
      .addCase(getKycUrl.fulfilled, (state, action) => {
        state.url = action.payload.data;
        state.loading = true;
      })
      .addCase(getKycUrl.rejected, (state) => {
        state.loading = false;
      });
  },
});
export default kycSlice.reducer;
export const { resetUrl } = kycSlice.actions;
