import { createSlice } from "@reduxjs/toolkit";
import { getTheme } from "../../../helpers";

export const themeSlice = createSlice({
    name: "alert",
    initialState: {
      isLight: getTheme() === "theme-light" ? true : false,
    },
    reducers: {
      setTheme: (state,action) => {
        state.isLight = action.payload;
      },
    }
  });
  export default themeSlice.reducer
  export const { setTheme } = themeSlice.actions;