export const createPairList = (marketPairList) => {
  let list = [];
  if (marketPairList?.length > 0) {
    list = marketPairList.map((val, idx) => {
      return { label: val.name, value: val.id };
    });
  }
  list.unshift({ label: "All", value: "all" });
  return list.length > 0 ? list : [{ label: "All PAIR", value: "all" }];
};

export const createPairListWithoutAll = (marketPairList) => {
  let list = [];
  if (marketPairList?.length > 0) {
    list = marketPairList.map((val, idx) => {
      return { label: val.name, value: val.id };
    });
  }
  // list.unshift({ label: "All", value: "" });
  return list.length > 0 && list
};