import CoinDetail from "../Components/Pages/CoinDetail/CoinDetail.jsx";
import {
  Dashboard,
  Login,
  ChangePassword,
  ResetPassword,
  CreateAcount,
  VerifyEmail,
  Identity,
  Portfolio,
  GridTrading,
  CopyTrading,
  VerifyIdentity,
  Setting,
  Order,
  Exchange,
  EmailConfirmation,
  Notifications,
  Landing,
  AdressManagement,
  Kyc,
} from "./lazyRoute";
import {
  sidebarRouteObj,
  publicRouteObj,
  primaryRoutes,
} from "../staticObjects/routing";

import Transactions from "../Components/Pages/Transactions/index.jsx";
import AccountActivity from "../Components/Pages/Setting/AccountActivity.jsx";
import DeviceManagement from "../Components/Pages/Setting/DeviceManagement.jsx";
// Public routing array

const {
  login,
  changePassword,
  resetPassword,
  createAccount,
  emailVerify,
  emailConfirmation,
  identity,
  verifyIdentity,
} = publicRouteObj;

export const publicRoutesData = [
  {
    path: 'trading',
    component: <Exchange />,
  },
  {
    path: `trading/:marketId`,
    component: <Exchange />,
  },
  {
    path: primaryRoutes.landing,
    component: <Landing />,
  },
  {
    path: login,
    component: <Login />,
  },
  {
    path: `${changePassword}/:id`,
    component: <ChangePassword />,
  },
  {
    path: resetPassword,
    component: <ResetPassword />,
  },
  {
    path: createAccount,
    component: <CreateAcount />,
  },
  {
    path: emailVerify,
    component: <VerifyEmail />,
  },
  {
    path: `${emailConfirmation}/:id`,
    component: <EmailConfirmation />,
  },
  {
    path: identity,
    component: <Identity />,
  },
  {
    path: verifyIdentity,
    component: <VerifyIdentity />,
  },
];

// Private routing array

const { home, port, advTrade, transactions } =
  sidebarRouteObj;
const {
  overview,
  setting,
  order,
  notfication,
  addressManagement,
  authLanding,
  kyc,
  deviceManagement,
  accountActivity,
  // referralViewAll,
} = primaryRoutes;

export const privateRoutesData = [
  {
    path: ``,
    component: <Dashboard />,
  },
  {
    path: authLanding,
    component: <Landing />,
  },
  {
    path: `${home}`,
    component: <Dashboard />,
  },
  {
    path: `${port}`,
    component: <Portfolio />,
  },
  {
    path: `${advTrade}`,
    component: <Exchange />,
  },

  {
    path: `${advTrade}/:marketId`,
    component: <Exchange />,
  },
  {
    path: `${transactions}`,
    component: <Transactions />,
  },

  {
    path: `${overview}/:id`,
    component: <CoinDetail />,
  },
  {
    path: `${setting}`,
    component: <Setting />,
  },

  {
    path: `${order}`,
    component: <Order />,
  },
  {
    path: `${notfication}`,
    component: <Notifications />,
  },
  { path: `${addressManagement}`, component: <AdressManagement /> },
  {
    path: `${kyc}`,
    component: <Kyc />,
  },
  {
    path: `${deviceManagement}`,
    component: <DeviceManagement />,
  },
  {
    path: `${accountActivity}`,
    component: <AccountActivity />,
  },
];
