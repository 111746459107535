import { createSlice } from "@reduxjs/toolkit";
import {
  fetchBuySellData,
  fetchBuySellHistory,
} from "../../services/buySellServices.js";

const buySellSlice = createSlice({
  name: "buySell",
  initialState: {
    list: [],
    total: 0,
    loading: false,
    support_data: [],
    error: false,
    current_Tab: "Buy",
    transStatus: false,
    buySellOpen: false
  },
  reducers: {
    setBuySellTab: (state, action) => {
      state.current_Tab = action.payload;
    },
    setTransStatus: (state, action) => {
      state.transStatus = action.payload;
    },
    setBuySellOpen: (state, action) => {
      state.buySellOpen = action.payload
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchBuySellHistory.pending, (state) => {
        state.error = false;
        state.loading = true;
        state.list = [];
        state.total = 0
      })
      .addCase(fetchBuySellHistory.fulfilled, (state, action) => {
        state.loading = false;
        state.list = action.payload.list;
        state.total = action.payload.total
      })
      .addCase(fetchBuySellHistory.rejected, (state, action) => {
        state.error = true;
        state.loading = false;
      })

      .addCase(fetchBuySellData.pending, (state) => {
        state.error = false;
        state.loading = true;
        state.support_data = [];
      })
      .addCase(fetchBuySellData.fulfilled, (state, action) => {
        state.loading = false;
        state.support_data = action.payload.data;
      })
      .addCase(fetchBuySellData.rejected, (state, action) => {
        state.error = true;
        state.loading = false;
      });
  },
});

export const { setBuySellTab, setTransStatus, selectedCurrency, setBuySellOpen } =
  buySellSlice.actions;

export default buySellSlice.reducer;
