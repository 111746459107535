import React, { Children, useEffect, useMemo, useRef, useState } from "react";
import style from "./style.module.scss";
import { Input, Row, Tooltip, Skeleton } from "antd";
import CommonButton from "../../Ui/button/CommonButton";
import { RightOutlined } from "@ant-design/icons";
import { StoreImages } from "../../Storeimgaes/StoreImages";
import { openCloseDivs } from "../../../staticObjects";
import {
  copytoClipBoard,
  preciseData,
  truncateData2,
  uppercase,
} from "../../../helpers";
import CustomInput from "../../Ui/customInput/CustomInput";
import { FocusWithIncreaseWidth } from "../../FocusAndIncreaseWidth";

const { copy, exclamination } = StoreImages;

const CommonSelectField = (props) => {
  const {
    amountRef,
    onChange,
    convertIcon,
    loading,
    onClickCoin,
    onClickFiat,
    selectedCoin,
    selectedFiat,
    amountErr,
    amount,
    desc,
    onClickPay,
    onClick,
    selectedPaymentMethod,
    selectedNetwork,
    onClickNetwork,
    balance,
    currency_usdt,
    isBuyTab,
  } = props;
  const { exchange, copy } = StoreImages;

  const renderComp = (child) => {
    if (loading) {
      return <Skeleton round active />;
    } else {
      return child;
    }
  };

  useEffect(() => {
    if (amountRef?.current) {
      setTimeout(() => {
        amountRef?.current?.focus();
      }, 500);

      // increase with of input field
      let dom = amountRef?.current?.input;
      dom?.addEventListener("input", () => {
        dom.style.width = dom.value.length + "ch";
      });
    }
  }, [amount]);

  {
    truncateData2(balance, selectedCoin?.currency_precision);
  }

  return (
    <>
      <div className={style.Buy}>
        <form onSubmit={onClick}>
          {renderComp(
            <Row className={`${style.Buy_row} sendForm`}>
              <div className="inputSec">
                <Input
                  value={amount}
                  onChange={(e) => onChange(e)}
                  name="amount"
                  placeholder="0.00"
                  ref={amountRef}
                  className="amountField"
                  disabled={loading}
                  autoComplete="off"
                  suffix={
                    <span>
                      {isBuyTab
                        ? selectedFiat?.currency
                        : selectedCoin?.currency}
                    </span>
                  }
                />
              </div>
            </Row>
          )}
          <div className={`errorCls buySellError`}>{amountErr}</div>

          {renderComp(
            <div className={` ${style.Buy_btn} card`}>
              <ul className={convertIcon}>
                <li onClick={onClickCoin}>
                  <span>I want to {isBuyTab ? "buy" : "sell"}</span>
                  <span>
                    <img
                      className={style.Buy_btn_icon}
                      src={selectedCoin?.icon}
                      alt="icon"
                    />
                    {selectedCoin?.currency}
                    <RightOutlined />
                  </span>
                </li>
              </ul>

              <ul className={convertIcon}>
                <li onClick={onClickFiat}>
                  <span>I want to {isBuyTab ? "pay" : "receive"}</span>
                  <span>
                    <img
                      className={style.Buy_btn_icon}
                      src={`data:image/svg+xml,${encodeURIComponent(
                        selectedFiat?.icon
                      )}`}
                      alt="icon"
                    />
                    {selectedFiat?.currency}
                    <RightOutlined />
                  </span>
                </li>
              </ul>

              <ul className={convertIcon}>
                <li onClick={onClickPay}>
                  <span>Payment method</span>
                  <span>
                    <img
                      className={style.Buy_btn_icon}
                      src={selectedPaymentMethod?.icon}
                      alt="icon"
                    />
                    {selectedPaymentMethod?.name}
                    <RightOutlined />
                  </span>
                </li>
              </ul>

              <ul className={convertIcon}>
                <li onClick={onClickNetwork}>
                  <span>Netwok</span>
                  <span>
                    {uppercase(selectedNetwork?.network_name)}
                    <RightOutlined />
                  </span>
                </li>
              </ul>
            </div>
          )}

          {isBuyTab &&
            renderComp(
              selectedNetwork?.address ? (
                <div className={style.buy_input}>
                  <h6 className="black14 ">
                    <span>{selectedCoin?.currency} address</span>
                    <img
                      className="curserPointer"
                      src={copy}
                      alt="icon"
                      onClick={() => copytoClipBoard(selectedNetwork?.address)}
                    />
                  </h6>
                  <p className="grey twelve">{selectedNetwork?.address}</p>
                </div>
              ) : (
                <div className={style.buy_input}>
                  <h6 className="black14 ">
                    <span>No {selectedCoin?.currency} Deposit address</span>
                  </h6>
                  <p className="grey twelve">
                    No {selectedCoin?.currency} deposit address has been
                    previously created.Please first create a deposit address{" "}
                  </p>
                </div>
              )
            )}

          {renderComp(
            <div className={style.Buy_btnMain}>
              <CommonButton
                title={
                  isBuyTab
                    ? `Buy ${uppercase(selectedCoin?.currency)}`
                    : `Sell ${uppercase(selectedCoin?.currency)}`
                }
                className={`btn ${!isBuyTab && "redBtn"}`}
                onClick={onClick}
                btntype="submit"
                disabled={isBuyTab && !selectedNetwork?.address}
              />
            </div>
          )}

          {!isBuyTab &&
            renderComp(
              <>
                <p className={`text ${style.Buy_text}`}>
                  Please refrain from sending any amount to the provided address
                  manually, as it is auto generated.
                </p>
                <div className={style.Buy_amount}>
                  <span>
                    Available {uppercase(selectedCoin?.currency)} Balance
                  </span>
                  <p>
                    <span>
                      {truncateData2(balance, selectedCoin?.currency_precision)}
                      {uppercase(selectedCoin?.currency)} =
                    </span>
                    <span> ${truncateData2(balance * currency_usdt, 2)}</span>
                  </p>
                </div>
              </>
            )}
        </form>
      </div>
    </>
  );
};

export default CommonSelectField;
