import React, { lazy } from "react";

export const Mainlayout = lazy(() =>
  import("../Components/Layout/Mainlayout/Mainlayout")
);
export const Dashboard = lazy(() => import("../Components/Pages/Dashboard"));
export const PageNotFound = lazy(() =>
  import("../Components/Pages/PageNotFound/PageNotFound")
);
export const Login = lazy(() => import("../Components/Pages/Login"));
export const ChangePassword = lazy(() => import("../Components/Pages/Login/PasswordSetting/ChangePassword"));
export const ResetPassword = lazy(() => import("../Components/Pages/Login/PasswordSetting/ResetPassword"));
export const CreateAcount = lazy(() =>
  import("../Components/Pages/SignUp/CreateAcount")
);
export const VerifyEmail = lazy(() =>
  import("../Components/Pages/SignUp/VerifyEmail")
);
export const Verifiaction = lazy(() =>
  import("../Components/Pages/SignUp/Verifiaction")
);
export const VerificationCode = lazy(() =>
  import("../Components/Pages/SignUp/VerificationCode")
);
export const Identity = lazy(() =>
  import("../Components/Pages/SignUp/Identity")
);
export const VerifyIdentity = lazy(() =>
  import("../Components/Pages/SignUp/VerifyIdentity")
);
export const Portfolio = lazy(() =>
  import("../Components/Pages/Portfolio/Portfolio")
);
export const GridTrading = lazy(() =>
  import("../Components/Pages/GridTrading/Grid/GridTradingComp.jsx")
);
export const Setting = lazy(() =>
  import("../Components/Pages/Setting/Setting")
);
export const DeviceManagement = lazy(() =>
  import("../Components/Pages/Setting/DeviceManagement.jsx")
);
export const Order = lazy(() =>
  import("../Components/Pages/ExchangePages/Order/Order")
);

export const Exchange = lazy(() =>
  import("../Components/Pages/ExchangePages/Exchange.jsx")
);

export const EmailConfirmation = lazy(() =>
  import("../Components/Pages/EmailConfirmation/index.jsx")
);

export const Notifications = lazy(() =>
  import("../Components/Pages/Setting/Notifications.jsx")
);
export const CopyTrading = lazy(() =>
  import("../Components/Pages/CopyTrading/CopyTrading.jsx")
);


export const Landing = lazy(() =>
  import("../Components/Pages/Landing/index.jsx")
);
export const AdressManagement = lazy(() =>
  import("../Components/Pages/DepositWithdraw/AddressMangement.jsx")
);

export const Kyc = lazy(() => 
import("../Components/Pages/Setting/Kyc.jsx")
)
