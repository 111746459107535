import { Modal } from "antd";

const CommonModal = (props) => {
  const { width, className, isOpen, onCancel, children } = props;
  return (
    <Modal
      className={`headerModals ${className}`}
      width={width ?? 415}
      centered
      visible={isOpen}
      onOk={onCancel}
      onCancel={onCancel}
      footer={null}
    >
      {children}
    </Modal>
  );
};

export default CommonModal;
