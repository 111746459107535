import React from "react";
import style from "./style.module.scss";
import HeadComman from "../../Common/HeadComman";
import { Row, Col } from "antd";

const PayWith = (props) => {
  const { onClick, paymentMethod, cbFun } = props;
  return (
    <>
      <div className={`${style.PayWith} `}>
        <HeadComman title="Payment method" onClick={onClick} />
        <div className={style.PayWith_body}>
          {paymentMethod.map((val, id) => (
            <Row key={id} className={style.PayWith_body_list} onClick={() => cbFun(val, "pay")}>
              <Col>
                <div className={style.PayWith_body_list_dflex}>
                  <img src={val.icon} alt="" />
                  <h6>
                    {val.name}
                    <br />
                    <span>{val.acountNumber}</span>
                  </h6>
                </div>
              </Col>
            </Row>
          ))}
        </div>
      </div>
    </>
  );
};

export default PayWith;
