import logo from "../Assets/Images/logo.png";
import logoWhite from "../Assets/Images/LogoWhite.png";
import home from "../Assets/Images/home.svg";
import notification from "../Assets/Images/notification.svg";
import bitCoin from "../Assets/Images/bit-coin.svg";
import application from "../Assets/Images/application.svg";
import downarrowDark from "../Assets/Images/downarrowdark.svg";
import etheImg from "../Assets/Images/ethereumcoin.svg";
import breadcrumb from "../Assets/Images/Breadcrumb.svg";
import arrow from "../Assets/Images/arrow.svg";
import exchange from "../Assets/Images/Exchange.svg";
import history from "../Assets/Images/history.svg";
import masterCard from "../Assets/Images/master-card.svg";
import backArrow from "../Assets/Images/back-Arrow.svg";
import WhiteBackArrow from "../Assets/Images/WhiteBackArrow.svg";
import binance from "../Assets/Images/binancecoin.svg";
import tron from "../Assets/Images/troncoin.svg";
import axis from "../Assets/Images/axis.svg";
import exclamination from "../Assets/Images/exclamation.svg";
import uparrows from "../Assets/Images/uparrows.svg";
import flow from "../Assets/Images/flow.svg";
import bitcoinImg from "../Assets/Images/bitcoin.svg";
import eth from "../Assets/Images/eth.svg";
import web from "../Assets/Images/web.svg";
import graphImg from "../Assets/Images/graphImg.svg";
import doc from "../Assets/Images/doc.svg";
import downarrows from "../Assets/Images/downarrows.svg";
import ToggleIcon from "../Assets/Images/ToggleIcon.svg";
import rightarrow from "../Assets/Images/rightarrow.svg";
import Password from "../Assets/Images/password.svg";
import verified from "../Assets/Images/verified.svg";
import mobile from "../Assets/Images/mobile.svg";
import blueVerified from "../Assets/Images/blue-verified.svg";
import cosmoslogo from "../Assets/Images/cosmos-atom-logo.svg";
import confirm from "../Assets/Images/confirmation.svg";
import dollers from "../Assets/Images/dollers.svg";
import notifications from "../Assets/Images/notifications.svg";
import copy from "../Assets/Images/copy.svg";
import searchIcon from "../Assets/Images/SearchIcon.svg";
import greenIcon from "../Assets/Images/greenicon.svg";
// import user from "../../redux/selector/user"
import search from "../Assets/Images/search.svg";
import mail from "../Assets/Images/mail.svg";
import openImg from "../Assets/Images/open.svg";
import feesImg from "../Assets/Images/fees.svg";
import swapping from "../Assets/Images/proreliable.svg";
import coin from "../Assets/Images/winwin.svg";
import cardExchange from "../Assets/Images/card-Exchange.svg";
import aiTrading from "../Assets/Images/automatedsys.svg";
import staking from "../Assets/Images/staking.svg";
import mobileApp from "../Assets/Images/lowentry.svg";
import engine from "../Assets/Images/matchingengine.svg";
import secure from "../Assets/Images/mutlichain.svg";
import liquidity from "../Assets/Images/multicrypto.svg";
import wallet from "../Assets/Images/securepayment.svg";
import trading from "../Assets/Images/quickpayment.svg";
import payment from "../Assets/Images/multipay.svg";
import buidCrypto from "../Assets/Images/spottradeicon.svg";
import tradeAnywere from "../Assets/Images/bonusIcon.svg";
import safeWallet from "../Assets/Images/gridtradeicon.svg";
import easySignup from "../Assets/Images/copyIco.svg";
import insta from "../Assets/Images/insta.svg";
import fb from "../Assets/Images/fb.svg";
import telegram from "../Assets/Images/telegram.svg";
import twitter from "../Assets/Images/twitter.svg";
import linkedIn from "../Assets/Images/linkin.svg";
import filledImg from "../Assets/Images/filled.svg";
import trustMobileNew from "../Assets/Images/trustMobilenew.png";
import selectAddress from "../Assets/Images/selectAddress.svg";
import AddressIcon from "../Assets/Images/AddressIcon.svg";
import copyIconblack from "../Assets/Images/copydark.svg";
import copyIcon from "../Assets/Images/copyicon.svg";
import DeleteIcon from "../Assets/Images/DeleteIcon.svg";
import ruleImg from "../Assets/Images/rules.svg";
import redemptionImg from "../Assets/Images/redemptioicon.svg";
import star from "../Assets/Images/Star.svg";
import review from "../Assets/Images/Review.svg";
import download from "../Assets/Images/doenload.svg";
import dlt from "../Assets/Images/dltIcon.svg";
import fundingImg from "../Assets/Images/funding.svg";
import submissionImg from "../Assets/Images/submission.svg";
import authentication from "../Assets/Images/authentication.svg";
import profilegroup from "../Assets/Images/profilegroup.svg";
import coingroup from "../Assets/Images/coingroup.svg"
import user2 from "../Assets/Images/user2.svg"
export const StoreImages = {
  user: require("../Assets/Images/user.png"),
  ListingCoin: require("../Assets/Images/iconlistedcoin.png"),
  daynight: require("../Assets/Images/daynight.png"),
  tribeImg: require("../Assets/Images/rally.png"),
  pricechart: require("../Assets/Images/grapghimg.png"),
  scanner: require("../Assets/Images/scanner.png"),
  barCode: require("../Assets/Images/barCode.png"),
  fuzzy: require("../Assets/Images/mobile.png"),
  robot: require("../Assets/Images/knoozexch.png"),
  trustMobile: require("../Assets/Images/trustMobile.png"),
  copyICONPNG: require("../Assets/Images/copyICON.png"),
  spotImg: require("../Assets/Images/spot.svg"),
  fundingImg: fundingImg,
  logo: logo,
  logoWhite: logoWhite,
  confirm: confirm,
  dollers: dollers,
  coingroup: coingroup,
  profilegroup, profilegroup,
  redemptionImg: redemptionImg,
  greenIcon: greenIcon,
  cosmoslogo: cosmoslogo,
  filledImg: filledImg,
  feesImg: feesImg,
  openImg: openImg,
  copyIcon: copyIcon,
  copyIconblack: copyIconblack,
  home: home,
  rightarrow: rightarrow,
  bitCoin: bitCoin,
  graphImg: graphImg,
  uparrows: uparrows,
  downarrows: downarrows,
  notification: notification,
  bitcoinImg: bitcoinImg,
  breadcrumb: breadcrumb,
  ruleImg: ruleImg,
  etheImg: etheImg,
  application: application,
  downarrowDark: downarrowDark,
  eth: eth,
  arrow: arrow,
  exchange: exchange,
  history: history,
  masterCard: masterCard,
  backArrow: backArrow,
  WhiteBackArrow: WhiteBackArrow,
  binance: binance,
  tron: tron,
  axis: axis,
  exclamination: exclamination,
  web: web,
  doc: doc,
  flow: flow,
  ToggleIcon: ToggleIcon,
  Password: Password,
  verified: verified,
  mobile: mobile,
  blueVerified: blueVerified,
  notifications: notifications,
  copy: copy,
  searchIcon: searchIcon,
  search: search,
  mail: mail,
  user2: user2,
  authentication: authentication,
  swapping: swapping,
  coin: coin,
  cardExchange: cardExchange,
  aiTrading: aiTrading,
  staking: staking,
  mobileApp: mobileApp,
  engine: engine,
  secure: secure,
  liquidity: liquidity,
  wallet: wallet,
  trading: trading,
  payment: payment,
  buidCrypto: buidCrypto,
  tradeAnywere: tradeAnywere,
  safeWallet: safeWallet,
  easySignup: easySignup,
  insta: insta,
  fb: fb,
  telegram: telegram,
  twitter: twitter,
  linkedIn: linkedIn,
  trustMobileNew: trustMobileNew,
  selectAddress: selectAddress,
  AddressIcon: AddressIcon,
  DeleteIcon: DeleteIcon,
  star: star,
  review: review,
  download: download,
  dlt: dlt,
  submissionImg: submissionImg,
};
