import { lowercase, uppercase } from ".";

export const currencyDropObj = (currencylist) => {
  let arr = [{ label: "ALL CURRENCIES", value: "" }];
  currencylist.length > 0 &&
    currencylist.filter(data => data.type === "coin").map((items) => {
      let data = { label: uppercase(items.id), value: lowercase(items.id) };
      arr.push(data);
    });
  return arr;
};
