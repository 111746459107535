export const p2p = {
  apiVersion: "p2p",
};

export const p2pWithHeader = {
  apiVersion: "p2p",
  withHeaders: true
};

export const peatio = {
  apiVersion: "peatio",
};

export const peatioWithHeader = {
  apiVersion: "peatio",
  withHeaders: true
};

export const invest = {
  apiVersion: "invest",
};

export const investWithHeader = {
  apiVersion: "invest",
  withHeaders: true
};

export const barong = {
  apiVersion: "barong",
};
export const barongWithHeader = {
  apiVersion: "barong",
  withHeaders: true
};
