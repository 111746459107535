import { createAsyncThunk } from "@reduxjs/toolkit";
import { API } from "../../api";
import { buildQueryString } from "../../helpers";
import * as config from "../../utils/apiVersion";
import { startLoading, stopLoading } from "../feature";
import { errorToastSelector, successToastSelector } from "../selector";
import { getUser, updateUserExtraData } from "./user";

export const getIdentity = createAsyncThunk(
  "login/getIdentity",
  async (payload, { dispatch }) => {
    try {
      dispatch(startLoading());
      let url = `identity`;
      const res = await API.post(config.barong)(url, payload);
      dispatch(stopLoading());
      return { data: res, status: true };
    } catch (e) {
      dispatch(stopLoading());
      dispatch(errorToastSelector(e));
      return false;
    }
  }
);

export const getOtpService = createAsyncThunk(
  "login/getOtpService",
  async ({ otpClicked, ...rest }, { dispatch }) => {
    try {
      let url = `identity/sessions/resent_otp`;
      await API.post(config.barong)(url, rest);
      dispatch(
        successToastSelector({
          message: `OTP ${otpClicked ? "resent" : "sent"}  successfully`,
        })
      );
      return { status: true };
    } catch (e) {
      dispatch(errorToastSelector(e));
      return false;
    }
  }
);

export const createIdentitySession = createAsyncThunk(
  "login/createIdentitySession",
  async (payload, { dispatch }) => {
    try {
      dispatch(startLoading());
      let url = `identity/sessions`;
      let res = await API.post(config.barong)(url, payload);
      localStorage.setItem("csrfToken", res.csrf_token);
      dispatch(successToastSelector({ message: "Login Successfully" }));
      dispatch(stopLoading());
      dispatch(getUser());
      dispatch(updateUserExtraData({
        user_time_zone: payload?.userTimeZone
      }))
      return { status: true, data: res };
    } catch (e) {
      dispatch(stopLoading());
      dispatch(errorToastSelector(e));
      return false;
    }
  }
);
