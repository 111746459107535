import React, { useState } from "react";
import { StoreImages } from "../../Storeimgaes/StoreImages";
import { useSelector } from "react-redux";

import style from "./style.module.scss";
import { lowercase } from "../../../helpers";
import { NoRecord } from "../../NoRecord";
import HeadComman from "../../Common/HeadComman";

const FiatList = (props) => {
  const [query, setQuery] = useState("");

  const { onClick, fiatList, cbFun, isBuyTab } = props;
  const testVal = (val) => lowercase(val)?.includes(lowercase(query));

  const searchAndRender = (listArr) => {
    if (query) {
      let filterData = listArr?.filter((data) => {
        let { icon, currency } = data;
        return testVal(icon) || testVal(currency);
      });
      return filterData;
    }
    return listArr;
  };

  const dataList = searchAndRender(fiatList);

  return (
    <>
      <div className={`card ${style.btnList}`}>
        <HeadComman
          title={isBuyTab ? "Pay" : "Receive"}
          onClick={onClick}
          query={query}
          setQuery={setQuery}
          withSearch={true}
        />
        <div className={style.btnList_content}>
          <ul>
            {dataList.length > 0 ? (
              dataList.map((val, id) => (
                <>
                  <li id={id} onClick={() => cbFun(val, "fiat")}>
                    <img
                      src={`data:image/svg+xml,${encodeURIComponent(val.icon)}`}
                      alt="icon"
                    />
                    <h6>
                      {val.currency}
                      <br />
                    </h6>
                  </li>
                </>
              ))
            ) : (
              <NoRecord />
            )}
          </ul>
        </div>
      </div>
    </>
  );
};

export default FiatList;
