import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { withTranslation } from "react-i18next";
import { generateSocketURI, streamsBuilder } from "../helpers/ranger";
import { rangerUrl } from "../api";
import { appendNewMessage, insertNotificationList } from "../redux/feature";
import {
  setKlineData,
  setMarketTickers,
  setOrderBook,
  setTrades,
  updateAllOrder,
  updateOpenOrder,
} from "../redux/feature/exchange/exchanges.slice";
import { isAuthenticated } from "../redux/selector";
import { message } from "antd";

function Ranger() {
  const dispatch = useDispatch();
  const isAuthenticate = useSelector((state) => isAuthenticated(state));
  const rangerBaseUrl = isAuthenticate
    ? `${rangerUrl()}/private`
    : `${rangerUrl()}/public`;
  const currentMarketId = useSelector(
    (state) => state?.exchange?.currentMarketId
  );
  const currentPeriod = useSelector((state) => state?.exchange?.klinePeriod);
  const streams = streamsBuilder(currentMarketId, currentPeriod);

  useEffect(() => {
    if (currentMarketId !== undefined) {
      const ws = new WebSocket(generateSocketURI(rangerBaseUrl, streams));
      ws.onmessage = function (event) {
        const json = JSON.parse(event.data);
        for (const routingKey in json) {
          switch (routingKey) {
            case "global.tickers":
              dispatch(setMarketTickers({ marketTickers: json[routingKey] }));
              break;
            case `${currentMarketId}.update`:
              dispatch(setOrderBook({ orderBook: json[routingKey] }));
              break;
            case `${currentMarketId}.trades`:
              setTimeout(() => {
                dispatch(setTrades({ trade: json[routingKey]?.trades[0] }));
              }, 200);
              break;
            case `${currentMarketId}.kline-${currentPeriod}`:
              dispatch(
                setKlineData({
                  data: [],
                  last: json[routingKey],
                  loading: false,
                  marketId: currentMarketId,
                  period: currentPeriod,
                })
              );
              break;
            case `order`:
              dispatch(updateOpenOrder(json[routingKey]));
              dispatch(updateAllOrder(json[routingKey]));
              break;
            case "appeal":
              dispatch(insertNotificationList(json["appeal"]));

              break;

            case "admin_action":
              if (json["admin_action"]?.notifiable_type === "Appeal" &&
                json["admin_action"]?.about === "admin_message") {
                message.info("New P2P admin message");
              }
              else {
                message.info(json["admin_action"]?.message);
              }
              dispatch(insertNotificationList(json["admin_action"]));
              break;
            case "advertisement":
              dispatch(insertNotificationList(json["advertisement"]));
              message.info(json["advertisement"]?.message);
              break;
            case "bid":
              dispatch(insertNotificationList(json["bid"]));
              message.info(json["bid"]?.message);
              break;
            case "message":
              dispatch(appendNewMessage(json["message"]));
              message.info("New P2P message");
              break;
            default:
              break;
          }
        }
      };
      return () => ws.close();
    }
  }, [currentPeriod, currentMarketId]);
}
export default withTranslation()(Ranger);