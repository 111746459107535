import { combineReducers } from "@reduxjs/toolkit";
import alertReducer from "./feature/alert/alert.slice";
import loaderReducer from "./feature/loader/loader.slice";
import loginReducer from "./feature/login/login.slice";
import userReducer from "./feature/user/user.slices";
import commonReducer from "./feature/common/common.Slice";
import exchangesReducer from "./feature/exchange/exchanges.slice";
import sendReceiveReducer from "./feature/sendReceive/sendReceive.slice";
import transHistoriesReducer from "./feature/transHistories/transHistories.Slice";
import settingReducer from "./feature/setting/setting.Slice";
import kycReducer from "./feature/kyc/kyc.slice"
import securityReducer from "./feature/securityManagement/security.Slices"
import themeReducer from "./feature/theme/theme.slice"
import buySellReducer from "./feature/buySell/buySell.slice"
import transakWidgetReducer from "./feature/transakWidgets/transakWidget.slice"
import notificationReducer from "./feature/notification/notification.slice"

export const rootReducer = combineReducers({
  alert: alertReducer,
  loader: loaderReducer,
  login: loginReducer,
  user: userReducer,
  commonApiData: commonReducer,
  sendReceive: sendReceiveReducer,
  exchange: exchangesReducer,
  transactionHistories: transHistoriesReducer,
  setting: settingReducer,
  kyc: kycReducer,
  security: securityReducer,
  theme: themeReducer,
  buySell: buySellReducer,
  transakWidget: transakWidgetReducer,
  notification: notificationReducer
});
