import React, { useEffect, useState } from "react";
import { Row, Col, message } from "antd";
import CommonButton from "../button/CommonButton.jsx";
import CustomSelect from "../../Ui/CustomSelect/CustomSelect";
import { useDispatch, useSelector } from "react-redux";
import { createPairList, currencyDropObj } from "../../../helpers";
import {
  buySellFilterOpt,
  convertFilterOpt,
  orderFilterOpt,
  receiveFilterOpt,
  sendFilterOpt,
  settingHisotryTabsKey,
} from "../../../staticObjects/primary.jsx";
import {
  getDepositHistory,
  getTransHisTrades,
  getWithdrawHistory,
} from "../../../redux/services/transHistories.js";
import {
  getBalanceHistory,
  getOrderHistory,
  getTrades,
} from "../../../redux/services";
import { viewForTrans } from "../../../redux/feature/index.js";
import { fetchBuySellHistory } from "../../../redux/services/buySellServices.js";
import DatePickerCustom from "../DatePickerCustom/DatePickerCustom.jsx";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { setFilter } from "../../../redux/feature/exchange/exchanges.slice.js";

function Filter(props) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { currenciesList, currentMarketId } = useSelector(
    (state) => state.commonApiData
  );
  const initData = {
    startDate: "",
    endDate: "",
  };
  const { marketPairList } = useSelector((state) => state.transactionHistories);
  const [selectCurrency, setSelectCurrency] = useState("");
  const [selectPair, setSelectPair] = useState("");
  const [filterStatus, setFilterStatus] = useState("");
  const [selectedDate, setSelectedDate] = useState({ ...initData });
  const { startDate, endDate } = selectedDate;
  const { buy, sell, convert, send, receive, orders, trades, transfer } =
    settingHisotryTabsKey;
  const { currentTab, cbFun } = props;

  const onloadSelectPair = () => {
    if (!marketPairList.length) return;
    const pairList = createPairList(marketPairList);
    let pair = currentMarketId ? currentMarketId : pairList[0]?.value;
    return { pair, zerothIndexPair: pairList[0]?.value };
  };

  useEffect(() => {
    if (!marketPairList?.length) return;
    setSelectPair(onloadSelectPair().pair);
  }, [marketPairList]);

  const isShowStatus = [buy, sell, convert, send, receive, orders].includes(
    currentTab
  );
  const isShowCurrency = [send, receive, transfer].includes(currentTab);
  const isShowPair = [orders, trades].includes(currentTab);

  const filterFun = (clicked = "filter") => {

    const init = { limit: 10, page: 1 };
    const unixStartDate = Math.floor(moment(startDate).valueOf() / 1000);
    // Add one day to the end date and then convert to Unix timestamp (in seconds)
    const unixEndDate = Math.floor(
      moment(endDate).add(1, "day").valueOf() / 1000
    );
    const isState =
      isShowStatus && filterStatus.length > 0
        ? filterStatus === "collected"
          ? { state: ["collected", "collecting"] }
          : { state: filterStatus }
        : {};
    const isCurrency =
      isShowCurrency && selectCurrency
        ? {
          [currentTab === transfer ? "currency_id" : "currency"]:
            selectCurrency,
        }
        : {};
    const isMarket = isShowPair && selectPair ? { market: selectPair } : {};
    const sendData =
      clicked === "filter"
        ? {
          ...init,
          ...isState,
          ...isCurrency,
          ...isMarket,

        }
        : {
          ...init,
          ...(isShowPair && {
            market: onloadSelectPair().zerothIndexPair

          }),
        };

    if ((currentTab === "trades" || currentTab === "orders") && clicked === "filter") {
      sendData["time_from"] = startDate && unixStartDate
      sendData["time_to"] = endDate && unixEndDate
    }
    cbFun({ ...sendData });
    switch (currentTab) {
      case buy:
        sendData["transak_type"] = "BUY";
        dispatch(fetchBuySellHistory({ ...sendData }));

        break;
      case sell:
        sendData["transak_type"] = "SELL";

        dispatch(fetchBuySellHistory({ ...sendData }));

        break;
      case send:
        dispatch(getWithdrawHistory({ ...sendData }));
        break;
      case receive:
        dispatch(getDepositHistory({ ...sendData }));
        break;
      case orders:
        if (endDate !== "" && startDate > endDate) {
          message.error(t("validations.fromDate"));
          return
        }
        dispatch(
          getOrderHistory({
            ...sendData,
            callFrom: "setting",
            orderBy: "desc",
          })
        );
        break;
      case trades:
        if (endDate !== "" && startDate > endDate) {
          message.error(t("validations.fromDate"));
          return
        }
        dispatch(setFilter({ ...sendData }));
        // dispatch(getTrades({ ...sendData }));
        dispatch(getTransHisTrades({ ...sendData }));
        break;
      case transfer:
        dispatch(
          getBalanceHistory({
            ...sendData,
            reference_type: "Account",
          })
        );
        break;
      default:
    }
  };

  const resetStates = () => {
    setSelectCurrency("");
    setFilterStatus("");
    setSelectedDate({
      startDate: "",
      endDate: ""
    })

    setSelectPair(onloadSelectPair().zerothIndexPair);
    dispatch(viewForTrans({ clickViewFrom: "", marketId: "" }));
  };

  const optionObj = () => {
    if (currentTab === buy || currentTab == sell) return buySellFilterOpt;
    if (currentTab === convert) return convertFilterOpt;
    if (currentTab === send) return sendFilterOpt;
    if (currentTab === receive) return receiveFilterOpt;
    if (currentTab === orders) return orderFilterOpt;
  };

  return (
    <>
      <div className="filtrspacing">
        <Row gutter={21}>
          <Col lg={20} md={24} xs={24}>
            <Row gutter={21}>
              {isShowCurrency && (
                <Col lg={6} xl={4} md={12} xs={24}>
                  <CustomSelect
                    placeholder="All Currency"
                    label="Select Currency"
                    drop_data={currencyDropObj(currenciesList)}
                    onChange={(currency) => setSelectCurrency(currency)}
                    value={selectCurrency}
                  />
                </Col>
              )}
              {isShowStatus && (
                <Col lg={6} xl={4} md={12} xs={24}>
                  <CustomSelect
                    placeholder="All Status"
                    label="Status"
                    drop_data={optionObj()}
                    onChange={(val) => setFilterStatus(val)}
                    value={filterStatus}
                  />
                </Col>
              )}

              {isShowPair && (
                <Col lg={6} xl={4} md={12} xs={24}>
                  <CustomSelect
                    placeholder="All Pair"
                    label="Pair"
                    drop_data={createPairList(marketPairList)}
                    onChange={(val) => setSelectPair(val)}
                    value={selectPair}
                  />
                </Col>
              )}

              {isShowPair &&
                <>
                  <Col lg={6} xl={4} md={12} xs={24}>
                    <div>
                      <DatePickerCustom
                        datepickerSimple
                        value={selectedDate?.startDate}
                        label={t("rewardRefHis.start_date")}
                        placeholder="2005-05-02"
                        className="datepickereasy"
                        allowClear={false}
                        onChange={(value, dateString) => {
                          const startdata = dateString ? moment(dateString) : "";
                          setSelectedDate({ ...selectedDate, startDate: startdata });
                        }
                        }
                      />
                    </div>
                  </Col>

                  <Col lg={6} xl={4} md={12} xs={24}>
                    <div>
                      <DatePickerCustom
                        datepickerSimple
                        value={selectedDate?.endDate}
                        label={t("rewardRefHis.end_date")}
                        placeholder="2005-05-02"
                        className="datepickereasy"
                        onChange={(value, dateString) => {
                          const enddate = dateString ? moment(dateString) : "";
                          setSelectedDate({ ...selectedDate, endDate: enddate });
                        }}
                      />
                    </div>
                  </Col>
                </>

              }
            </Row>
          </Col>
          <Col lg={24} xl={4} md={24} xs={24}>
            <div className="mb24 centerbutns">
              <CommonButton
                title="Search"
                className="btn"
                onClick={() => filterFun("filter")}
              />
              <CommonButton
                title="Reset"
                className="btn"
                onClick={() => {
                  resetStates();
                  filterFun("reset");
                }}
              />
            </div>
          </Col>
        </Row>
      </div>
    </>
  );
}

export default Filter;
