import React, { useEffect } from "react";
import style from "../Setting/style.module.scss";
import TransactionsHistory from "../Setting/TransactionsHistory";
import { getCurrencies } from "../../../redux/services";
import { useDispatch } from "react-redux";

function Transactions() {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getCurrencies());
  }, []);
  return (
    <div className={`${style.settingPage} ${style.Outer} transactionmargin card`}>
      <div className={`${style.settingPage_settingtabs} settingtabs`}>
        <TransactionsHistory />
      </div>
    </div>
  );
}

export default Transactions;
