import { createAsyncThunk } from "@reduxjs/toolkit";
import { API } from "../../api";
import * as config from "../../utils/apiVersion";
import { startLoading, stopLoading } from "../feature";
import { errorToastSelector } from "../selector";

export const getKycUrl = createAsyncThunk(
  "kyc/getKycUrl",
  async (payload, { dispatch }) => {
    try {
      dispatch(startLoading());
      let url = `resource/shufti_pro/verification_url`;
      const res = await API.post(config.barong)(url, payload);
      dispatch(stopLoading());
      return { data: res, status: true };
    } catch (e) {
      dispatch(stopLoading());
      dispatch(errorToastSelector(e));
      return false;
    }
  }
);