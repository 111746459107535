import { DatePicker } from "antd";
import React from "react";
import "./DatePickerCustom.scss";
import { useState, useRef, useEffect } from 'react';
function DatePickerCustom(props) {

  const { RangePicker } = DatePicker;
  const {
    datepickerSimple,
    datepickerSimpleStyle,
    datepickerAdvance,
    datepickerAdvanceStyle,
    datepickerTime,
    className,
    label,
    onChange,
    labelcustom,
    value,
  } = props;


  return (
    <div className="selectdatefix">
      {label ? <label className={`label ${labelcustom}`}>{label}</label> : null}
      {datepickerSimple && (
        <DatePicker
          getPopupContainer={trigger => trigger.parentElement}
          label
          className={` customDatePicker ${datepickerSimpleStyle && "datepickerSimple"
            } ${className} `}
          placeholder="YY-MM-DD"
          disabledDate={(d) => !d || d.isAfter(new Date())}
          type="date"
          onChange={onChange}
          inputReadOnly
          value={value}
        />
      )}
      {datepickerAdvance && (
        <RangePicker
          className={` customDatePicker  ${datepickerAdvanceStyle && "datepickerAdvance"
            } $`}
        />
      )}
      {datepickerTime && (
        <RangePicker
          showTime
          className={` customDatePicker $ ${datepickerTime && "datepickerTime"
            }`}
        />
      )}
    </div>
  );
}

export default DatePickerCustom;
