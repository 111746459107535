import React, { useEffect, useState } from "react";
import style from "./style.module.scss";
import { Table } from "antd";
import { useDispatch, useSelector } from "react-redux";
import {
  createPairList,
  dateToUtc,
  getCloneData,
  preciseData,
  removeTrailingZero,
  uppercase,
} from "../../../helpers";
import { getOrderHistory } from "../../../redux/services";
import CustomPagination from "../../customHooks/customPagination";
import {
  ordersType,
  settingHisotryTabsKey,
  settingOrdersTableDataIndex,
} from "../../../staticObjects";
import { checkOrderState, orderSideColor } from "../../../helpers/OrdersState";
import moment from "moment";
import Filter from "../../Ui/filter/Filter";
import ExportData from "../../Ui/exportData";

function Orders(props) {
  const dispatch = useDispatch();
  const { allOrders: orders, allOrdersCount } = useSelector(
    (state) => state?.exchange
  );
  const { currentMarketId } = useSelector((state) => state?.commonApiData);
  const { marketPairList } = useSelector((state) => state.transactionHistories);
  const [orderList, setOrderList] = useState([]);
  const [filterObj, setFilterObj] = useState({});
  const [flag, setFlag] = useState(true);
  const [paginationObj, setPaginationObj] = useState({
    pageNo: 1,
    limit: 10,
    orderBy: "desc",
  });

  const { fullOrder, partialOrder, newOrder } = ordersType;
  const {
    datetime,
    pair,
    type,
    side,
    average,
    price,
    filled,
    remainingAmt,
    total,
    status,
  } = settingOrdersTableDataIndex;

  const apiCall = (data) => {
    if (!marketPairList.length) return;
    const pairList = createPairList(marketPairList);
    let toSendMarket = currentMarketId ? currentMarketId : pairList[0].value;
    setFlag(false);
    dispatch(
      getOrderHistory({
        limit: paginationObj?.limit,
        page: paginationObj?.pageNo,
        order_by: paginationObj?.orderBy,
        callFrom: "setting",
        market: toSendMarket,
        ...filterObj,
        ...data,
      })
    );
  };

  useEffect(() => {
    // prevent api calling multiple time
    if (flag) {
      apiCall({});
    }
  }, [marketPairList]);

  useEffect(() => {
    if (orders?.length > 0) {
      fillList();
    }
    return () => setOrderList([]);
  }, [orders]);

  const priceFixed = orders?.length > 0 ? orders?.price_precision : undefined;
  const totalPrecision = (id) => {
    const findItem = orders.find((item) => item.id === id);
    return findItem?.amount_precision + findItem?.price_precision;
  };

  const checkType = (order) => {
    const type =
      order?.remaining_volume === order?.origin_volume &&
        order?.trades_count == 0
        ? newOrder
        : order?.remaining_volume === "0.0"
          ? fullOrder
          : partialOrder;
    return type;
  };

  function fillList() {
    let tempOrders = orders && getCloneData(orders);
    const updatedList = tempOrders?.map((row) => {
      let isCancel = row?.state === "cancel";
      let isCount0 = row?.trades_count === 0;
      return {
        // datetime: <p>{moment(row?.updated_at)?.format("DD-MM-YYYY  HH:mm")}</p>,
        datetime: <p>{dateToUtc(row?.updated_at, "DD/MM/YYYY  HH:mm")}</p>,
        pair: <p>{uppercase(row?.market)}</p>,
        type: (
          <p>
            {row?.ord_type.charAt(0).toUpperCase() + row?.ord_type.slice(1)}
          </p>
        ),
        side: (
          <div className={style.order_side}>
            <div
              className="btnsmall1"
              style={{
                backgroundColor: orderSideColor(row?.side),
              }}
            >
              <small>
                {row?.side.charAt(0).toUpperCase() + row?.side.slice(1)}{" "}
              </small>
            </div>
            <div
              className="btnsmall1 btnsmall2"
              style={{
                backgroundColor:
                  checkType(row) == "New"
                    ? "#008cff"
                    : checkType(row) == "Full"
                      ? "#76cb9e"
                      : "#cb767a",
              }}
            >
              <small>{checkType(row)}</small>
            </div>
          </div>
        ),
        average: (
          <div>
            {isCancel && isCount0
              ? "--"
              : `${priceFixed !== undefined
                ? removeTrailingZero(
                  preciseData(row?.avg_price, priceFixed)
                )
                : row?.avg_price
              } (${uppercase(row?.bid)})`}
          </div>
        ),
        price: (
          <div>
            {["limit", "stop_loss_limit", "take_profit_limit"].includes(
              row?.ord_type
            )
              ? `${priceFixed !== undefined
                ? preciseData(row?.price, priceFixed)
                : row?.price
              } (${uppercase(row?.bid)})`
              : "Market"}
          </div>
        ),
        filled: (
          <div>
            {isCancel && isCount0
              ? "--"
              : `${removeTrailingZero(row?.executed_volume)} (${uppercase(
                row?.ask
              )})`}
          </div>
        ),
        remainingAmt: (
          <div
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <p>
              {isCancel && isCount0
                ? "--"
                : `${removeTrailingZero(row?.remaining_volume)} (${uppercase(
                  row?.ask
                )})`}
            </p>
          </div>
        ),
        total: (
          <p>
            {isCancel
              ? "--"
              : totalPrecision(row.id) !== undefined
                ? removeTrailingZero(
                  preciseData(
                    row?.avg_price * row?.executed_volume,
                    totalPrecision(row.id)
                  )
                )
                : row?.avg_price * row?.executed_volume}
          </p>
        ),
        status: (
          <span style={{ color: orderSideColor(row?.state) }}>
            {checkOrderState(row?.state)}
          </span>
        ),
      };
    });
    setOrderList(updatedList);
  }

  const columns = [
    {
      title: "Date/Time",
      dataIndex: datetime,
    },
    {
      title: "Pair",
      dataIndex: pair,
    },
    {
      title: "Type",
      dataIndex: type,
    },
    {
      title: "Side",
      dataIndex: side,
    },
    {
      title: "Average",
      dataIndex: average,
    },
    {
      title: `Price`,
      dataIndex: price,
    },
    {
      title: `Filled`,
      dataIndex: filled,
    },
    {
      title: `Remaining Amount`,
      dataIndex: remainingAmt,
    },
    {
      title: "Total",
      dataIndex: total,
    },
    {
      title: "Status",
      dataIndex: status,
    },
  ];

  const callBackFun = (obj) => {
    const { page, limit, ...rest } = obj;
    setPaginationObj({
      ...paginationObj,
      pageNo: page,
      limit: limit,
    });
    setFilterObj({ ...rest });
  };

  return (
    <div className={style.order_Table}>
      {props.isFilterVisible && (
        <Filter currentTab={settingHisotryTabsKey.orders} cbFun={callBackFun} />
      )}
      {props.isExportVisible && (
        <ExportData currentTab={settingHisotryTabsKey.orders} />
      )}
      <Table
        dataSource={orderList}
        columns={columns}
        pagination={false}
        className="portfolioTable height450Table"
      />
      <CustomPagination
        pageNo={paginationObj.pageNo}
        limit={paginationObj.limit}
        total={allOrdersCount}
        onChange={(pageNo, pageSize) => {
          apiCall({ page: pageNo, limit: pageSize });
          setPaginationObj({
            ...paginationObj,
            pageNo: pageNo,
            limit: pageSize,
          });
        }}
      />
    </div>
  );
}

export default Orders;
