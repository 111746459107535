import { createAsyncThunk } from "@reduxjs/toolkit";
import * as config from "../../utils/apiVersion";
import { API } from "../../api";
import { buildQueryString } from "../../helpers";
import { startLoading, stopLoading } from "../feature";
import { errorToastSelector } from "../selector";

export const fetchBuySellHistory = createAsyncThunk(
    "buySell/fetchBuySellHistory",
    async (payload, { rejectWithValue, dispatch }) => {
        try {
            dispatch(startLoading());
            let url = `/account/tranask?${buildQueryString(payload)}`;
            const { data, headers } = await API.get(config.peatioWithHeader)(url);
            dispatch(stopLoading());
            return { list: data, total: headers.total };
        } catch (e) {
            dispatch(stopLoading());
            dispatch(errorToastSelector(e, "error"));
            return rejectWithValue(e);
        }
    }
);

export const fetchBuySellData = createAsyncThunk(
    "buySell/fetchBuySellData",
    async (payload, { rejectWithValue, dispatch }) => {
        try {
            dispatch(startLoading());
            let url = `account/transak/support_data?${buildQueryString(
                payload
            )}`;
            const data = await API.get(config.peatio)(url);
            dispatch(stopLoading());
            return {data, status: true};
        } catch (e) {
            dispatch(stopLoading());
            dispatch(errorToastSelector(e, "error"));
            return rejectWithValue(e);
        }
    }
);