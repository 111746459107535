export const defaultConfig = {
  title: "Knooze Exchange",
  baseUrl: "https://stage-exchange.knooz.com",
  api: {
    authUrl: "https://stage-exchange.knooz.com/api/v2/barong",
    tradeUrl: "https://stage-exchange.knooz.com/api/v2/peatio",
    p2pUrl: "https://stage-exchange.knooz.com/api/v2/p2p",
    rangerUrl: "wss://stage-exchange.knooz.com/api/v2/ranger",
  },
  
  captcha: {
    captchaType: "recaptcha",
    siteKey: "6LcqncInAAAAAMhVMGzxBBMuNpkgmWSDv9Uwfsld",
  },
  withCredentials: true,
  usdt_value: 1,
};

export const Frontend = {
  config: defaultConfig,
};

window.env = window.env || defaultConfig;
Frontend.config = { ...window.env };

Frontend.config.captcha = Frontend.config.captcha || defaultConfig.captcha;
Frontend.config.cloudCaptcha =
  Frontend.config.cloudCaptcha || defaultConfig.cloudCaptcha;

export const baseUrl = () => Frontend.config.baseUrl;
export const authUrl = () => Frontend.config.api.authUrl;
export const tradeUrl = () => Frontend.config.api.tradeUrl;
export const withCredentials = () => Frontend.config.withCredentials;
export const p2pUrl = () => Frontend.config.api.p2pUrl;
export const rangerUrl = () => Frontend.config.api.rangerUrl;
export const cloudCaptchaSiteKey = () => Frontend.config.cloudCaptcha.siteKey;
export const cloudCaptchaCaptchaType = () =>
  Frontend.config.cloudCaptcha.captchaType;
export const usdtValue = () => Frontend.config.usdt_value;
