export const publicRouteObj = {
  login: "login",
  changePassword: "changePassword",
  resetPassword: "resetPassword",
  createAccount: "createAccount",
  emailVerify: "emailVerify",
  emailConfirmation: "emailConfirmation",
  identity: "identity",
  verifyIdentity: "verifyIdentity",
};

// Sidebar obj
export const sidebarRouteObj = {
  home: "home",
  port: "portfolio",
  advTrade: "advanceTrading",
  advTradePublic: "trading",
  p2p: "p2p",
  transactions: "transactions",
};

const {
  home,
  port,
  advTrade,
  p2p,
  transactions,
} = sidebarRouteObj;

export const defaultActiveSidebarObj = {
  [home]: ["1", "home", "homeIcon"],
  [port]: ["2", "port", "portIcon"],
  [advTrade]: ["3", "advTrade", "advTradeIcon"],
  [p2p]: ["4", "p2p", "p2pIcon"],
};

export const routeExists = [
  home,
  port,
  advTrade,
  p2p,
];

export const primaryRoutes = {
  verification: "verification",
  verificationCode: "verificationCode",
  overview: "overview",
  setting: "setting",
  accountActivity: "account-activity",
  order: "order",
  transactions: "transactions",
  landing: "landing",
  notfication: "notfication",
  addressManagement: "address-management",
  introWithoutId: "intro",
  authLanding: "authLanding",
  kyc: "kyc",
  deviceManagement: "device-management",
};

const {
  setting,
  order,
  addressManagement,
  viewAll,
  viewHistory,
} = primaryRoutes;

export const headerHeadingObj = {
  [home]: "Home",
  [port]: "Portfolio",
  [advTrade]: "Trading",
  [p2p]: "P2P",
  [transactions]: "Transactions History",
  [setting]: "Settings",
  [order]: "Orders",
  [addressManagement]: "Address Management",
  [viewAll]: "My Earnings",
  [viewHistory]: "History",
};

export const rangerRoutes = [advTrade, port];
