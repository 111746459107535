import { createSlice } from "@reduxjs/toolkit";
import {
  getAutoGridData,
  getFavMarketList,
  getGridDetailOrderHistory,
  getGridDetailPendingOrder,
  getGridSettingDetail,
  getGridSettings,
  getMarketGraph,
  getMarketTickers,
  getMarkets,
  getOrderBook,
  getOrderHistory,
  getTrades,
  getTradingRules,
} from "../../services";
import { klineArrayToObject } from "../../../helpers/ranger";
import {
  getCloneData,
  insertOrUpdate,
  insertOrUpdateHistory,
} from "../../../helpers";

const initialState = {
  orderBookList: [],
  tradeList: [],
  tradesCount: 0,
  marketList: [],
  currentMarket: {},
  marketTickers: {},
  currentMarketId: "",
  baseCurrency: {},
  success: false,
  error: false,
  loading: false,
  kline: {
    data: [],
    last: undefined,
    loading: false,
    marketId: undefined,
    period: undefined,
  },
  klinePeriod: "15m",
  askCurrentValue: undefined,
  bidCurrentValue: "",
  orderHistory: [],
  allOrders: [],
  allOrdersCount: 0,
  tradingRules: [],
  marketGraph: [],
  favMarketList: [],
  tradeType: "spot",
  selectedGridPane: "Running",
  filter: {}
};

export const exchangeSlice = createSlice({
  name: "exchangeSlice",
  initialState: initialState,
  reducers: {
    setCurrentMarket: (state, action) => {
      state.currentMarket = action.payload.currentMarket;
      state.currentMarketId = action.payload.currentMarketId;
    },
    setBaseCurrency: (state, action) => {
      state.baseCurrency = action.payload.baseCurrency;
    },
    setMarketTickers: (state, action) => {
      state.marketTickers = action.payload.marketTickers;
    },
    setOrderBook: (state, action) => {
      state.orderBookList = action.payload.orderBook;
    },
    setTrades: (state, action) => {
      let trades = getCloneData(state.tradeList);
      if(trades){
        trades.splice(0, 1);
        trades.push(action?.payload?.trade);
        state.tradeList = trades;
      }
    },
    resetCurrentMarketId: (state, action) => {
      state.currentMarketId = "";
    },
    setKlinePeriod: (state, action) => {
      state.klinePeriod = action.payload;
    },
    setKlineData: (state, action) => {
      state.kline.data = [];
      state.kline.last = klineArrayToObject(action.payload.last);
      state.kline.marketId = action.payload.marketId;
      state.kline.period = action.payload.period;
    },
    resetKlineData: (state, action) => {
      state.kline.data = [];
      state.kline.last = undefined;
      state.kline.marketId = undefined;
      state.kline.period = undefined;
    },
    setAskVal: (state, action) => {
      state.askCurrentValue = action?.payload;
    },
    setBidVal: (state, action) => {
      state.bidCurrentValue = action?.payload;
    },
    resetBidVal: (state, action) => {
      state.bidCurrentValue = "";
    },
    setSuccess: (state, action) => {
      state.success = action?.payload;
    },
    setExchangeState: (state, action) => {
      state[action?.payload?.key] = action?.payload?.data;
    },
    updateOpenOrder: (state, action) => {
      let openOrderList = getCloneData(state.orderHistory);
      let newData = insertOrUpdate(openOrderList, action.payload);
      state.orderHistory = newData;
    },
    updateAllOrder: (state, action) => {
      let allOrderList = getCloneData(state.allOrders);
      const arr = insertOrUpdateHistory(allOrderList, action.payload);
      state.allOrders = arr;
    },
    setActiveTradeType: (state, action) => {
      state.tradeType = action.payload;
    },
    setGridPane: (state, action) => {
      state.selectedGridPane = action?.payload;
    },
    setFilter: (state, action) => {
      state.filter = action?.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getOrderBook.pending, (state) => {
        state.error = false;
        state.orderBookList = [];
      })
      .addCase(getOrderBook.fulfilled, (state, action) => {
        state.loading = false;
        state.orderBookList = action.payload.list;
      })
      .addCase(getOrderBook.rejected, (state, action) => {
        state.error = true;
      })
      .addCase(getTrades.pending, (state) => {
        state.error = false;
        state.tradeList = [];
      })
      .addCase(getTrades.fulfilled, (state, action) => {
        state.loading = false;
        state.tradeList = action?.payload?.list;
        state.tradesCount = action?.payload?.total;
      })
      .addCase(getTrades.rejected, (state, action) => {
        state.error = true;
      })
      .addCase(getMarkets.pending, (state) => {
        state.error = false;
      })
      .addCase(getMarkets.fulfilled, (state, action) => {
        state.loading = false;
        state.marketList = action?.payload?.list;
      })
      .addCase(getMarkets.rejected, (state, action) => {
        state.error = true;
      })
      .addCase(getMarketTickers.pending, (state) => {
        state.error = false;
      })
      .addCase(getMarketTickers.fulfilled, (state, action) => {
        state.loading = false;
        state.marketTickers = action.payload.list;
      })
      .addCase(getMarketTickers.rejected, (state, action) => {
        state.error = true;
      })

      .addCase(getOrderHistory.pending, (state) => {
        state.error = false;
        state.orderHistory = [];
        state.allOrders = [];
      })
      .addCase(getOrderHistory.fulfilled, (state, action) => {
        state.loading = false;
        state.total = action.payload.total;
        if (action.payload.callFrom === "setting") {
          state.allOrders = action?.payload?.list;
          state.allOrdersCount = action?.payload?.total ?? 0;
        } else {
          if (action?.payload?.state === "wait") {
            state.orderHistory = action?.payload?.list;
          } else {
            state.allOrders = action?.payload?.list;
            state.allOrdersCount = action?.payload?.total ?? 0;
          }
        }
      })
      .addCase(getOrderHistory.rejected, (state, action) => {
        state.error = true;
      })

      .addCase(getTradingRules.pending, (state) => {
        state.error = false;
      })
      .addCase(getTradingRules.fulfilled, (state, action) => {
        state.loading = false;
        state.tradingRules = action?.payload?.list;
      })
      .addCase(getTradingRules.rejected, (state, action) => {
        state.error = true;
      })
      .addCase(getMarketGraph.pending, (state) => {
        state.error = false;
      })
      .addCase(getMarketGraph.fulfilled, (state, action) => {
        state.loading = false;
        state.graphData = action.payload.list;
      })
      .addCase(getMarketGraph.rejected, (state, action) => {
        state.error = true;
      })
      .addCase(getFavMarketList.pending, (state) => {
        state.error = false;
      })
      .addCase(getFavMarketList.fulfilled, (state, action) => {
        state.loading = false;
        state.favMarketList = action.payload.list;
      })
      .addCase(getFavMarketList.rejected, (state, action) => {
        state.error = true;
      })
  },
});

export const {
  setCurrentMarket,
  setFilter,
  setBaseCurrency,
  setMarketTickers,
  setOrderBook,
  setTrades,
  resetCurrentMarketId,
  setKlineData,
  setKlinePeriod,
  setAskVal,
  setBidVal,
  resetBidVal,
  resetKlineData,
  setSuccess,
  setExchangeState,
  updateOpenOrder,
  updateAllOrder,
  setActiveTradeType,
  setGridPane,
} = exchangeSlice.actions;
export default exchangeSlice.reducer;
