import React, { useEffect, useState } from "react";
import { Table } from "antd";
import style from "./style.module.scss";
import {
  TradesTableDataIndex,
  settingHisotryTabsKey,
  transHistoryTableDataIndex,
} from "../../../staticObjects";
import { useDispatch, useSelector } from "react-redux";
import CustomPagination from "../../customHooks/customPagination";
import {
  getBalanceHistory,
  getDepositHistory,
  getTrades,
  getTransHisTrades,
  getWithdrawHistory,
} from "../../../redux/services";
import i18n from "../../../i18n";
import moment from "moment";
import {
  createPairList,
  dateToUtc,
  lowercase,
  uppercase,
} from "../../../helpers";
import Filter from "../../Ui/filter/Filter";
import ExportData from "../../Ui/exportData";
import { fetchBuySellHistory } from "../../../redux/services/buySellServices";

const { buy, sell, send, receive, trades, transfer } = settingHisotryTabsKey;
const { date, pair, side, avg_price, price, amount, total, fee } = TradesTableDataIndex;

const replaceTxid = (link, repl) => {
  return link !== undefined && link !== null && link.replace("#{txid}", repl);
};

const sendReceiveColumns = () => {
  return [
    {
      title: <p className="coins">Status</p>,
      dataIndex: "Status",
      key: "Status",
    },
    {
      title: "Amount",
      dataIndex: "Amount",
      key: "Amount",
    },
    {
      title: "Currency",
      dataIndex: "Currency",
      key: "Currency",
    },
    {
      title: "Network",
      dataIndex: "Network",
      key: "Network",
    },
    {
      title: "Date/Time",
      dataIndex: "Date",
      key: "Date",
    },
    {
      title: "Txid",
      dataIndex: "Txid",
      key: "Txid",
    },
  ];
};

const tradeColumns = () => {
  return [
    {
      title: "Date/Time",
      dataIndex: date,
    },
    {
      title: "Pair",
      dataIndex: pair,
    },
    {
      title: "Side",
      dataIndex: side,
    },
    {
      title: "Average",
      dataIndex: avg_price,
    },
    {
      title: "Price",
      dataIndex: price,
    },
    {
      title: "Amount",
      dataIndex: amount,
    },
    {
      title: "Total",
      dataIndex: total,
    },
    {
      title: "Fee",
      dataIndex: fee,
    },
  ];
};

const buySellColumns = (current_tab) => {
  return [
    {
      title: <p className="coins">Status</p>,
      dataIndex: "Status",
    },
    {
      title: current_tab === "buy" ? "You receive" : "You Pay",
      dataIndex: "crypto_amount",
    },
    {
      title: current_tab === "buy" ? "You Pay" : "You receive",
      dataIndex: "fiat_amount",
    },
    {
      title: "Fee",
      dataIndex: "Fee",
    },
    {
      title: "Network",
      dataIndex: "Network",
    },
    {
      title: "Date/Time",
      dataIndex: "Date",
    },
    {
      title: "Txid",
      dataIndex: "Txid",
    },
  ];
};

const transferColumns = () => {
  return [
    {
      title: <p className="coins">Date/Time</p>,
      dataIndex: "Date",
      key: "Date",
    },
    {
      title: "Currency",
      dataIndex: "Currency",
      key: "Currency",
    },
    {
      title: "From",
      dataIndex: "From",
      key: "From",
    },
    {
      title: "To",
      dataIndex: "To",
      key: "To",
    },
    {
      title: "Amount",
      dataIndex: "Amount",
      key: "Amount",
    },
  ];
};

const transferData = (datalist) => {
  let createList = datalist.map((val, idx) => {
    const { reference_kind, amount, created_at, currency } = val;
    let isSwapP2PBal = reference_kind === "swap_p2p_balance";
    let from = isSwapP2PBal ? "P2P" : "Spot";
    let to = isSwapP2PBal ? "Spot" : "P2P";

    return {
      key: idx,
      Date: dateToUtc(created_at, "DD/MM/YYYY  HH:mm"),
      Currency: uppercase(currency),
      From: from,
      To: to,
      Amount: amount,
    };
  });
  return createList;
};

const createLink = (txid, isInternal, explorer_transaction) => {
  return txid ? (
    <>
      {isInternal ? (
        <div>Internal Transaction</div>
      ) : (
        <a
          target="_blank"
          className="overflowText"
          href={replaceTxid(explorer_transaction, txid)}
          title={txid}
        >
          {txid}
        </a>
      )}
    </>
  ) : (
    "N/A"
  );
};

const buySellData = (dataList, currentTab) => {
  const isBuy = currentTab === buy;
  const { t } = i18n;

  let createList = dataList.map((val, idx) => {
    const {
      blockchain_txid,
      txid,
      fiat_amount,
      fee_in_fiat,
      crypto_amount,
      explorer_transaction,
      network,
      blockchain_key,
      amount,
      created_at,
      status,
      crypto_currency,
      currency,
      fiat_currency,
    } = val;

    const state = `${t(`statusState.${status}`)}`;
    const isInternal = val?.transaction_type === "internal";

    return {
      key: idx,
      Status: <h6 className={`${lowercase(state)}`}>{state}</h6>,
      Date: dateToUtc(created_at, "DD/MM/YYYY  HH:mm"),
      Fee: `${fee_in_fiat} ${fiat_currency}`,
      fiat_amount: `${fiat_amount} ${fiat_currency}`,
      crypto_amount: `${crypto_amount} ${crypto_currency}`,

      Currency: uppercase(currency),
      Network: uppercase(network),
      Txid: createLink(
        isBuy ? blockchain_txid : txid,
        isInternal,
        explorer_transaction
      ),
    };
  });

  return createList;
};

const sendReceiveData = (datalist, currentTab) => {
  const { t } = i18n;
  const isSend = currentTab === send;
  let createList = datalist.map((val, idx) => {
    const {
      blockchain_txid,
      txid,
      explorer_transaction,
      blockchain_key,
      amount,
      created_at,
      state,
      currency,
    } = val;

    const status = `${t(`statusState.${state}`)}`;
    const isInternal = val?.transaction_type === "internal";

    return {
      key: idx,
      Status: <h6 className={`${lowercase(status)}`}>{status}</h6>,
      Date: dateToUtc(created_at, "DD/MM/YYYY  HH:mm"),
      Amount: amount,
      Currency: uppercase(currency),
      Network: blockchain_key?.split("-")[0]?.toUpperCase(),
      Txid: createLink(
        isSend ? blockchain_txid : txid,
        isInternal,
        explorer_transaction
      ),
    };
  });
  return createList;
};

const tradesData = (tradeList) => {
  let updatedList = tradeList.length > 0 && tradeList.map((item, idx) => {
    const price_value = item?.ord_type === "market" ? "Market" : item?.price;
    return {
      [date]: <p>{dateToUtc(item?.created_at, "DD/MM/YYYY  HH:mm")}</p>,
      [pair]: <p>{uppercase(item?.market)}</p>,
      [side]: <p>{uppercase(item?.side)}</p>,
      [price]: <p>{price_value}</p>,
      [avg_price]: <p>{item?.avg_price}</p>,
      [amount]: <p>{item?.amount}</p>,
      [total]: <p>{item?.total}</p>,
      [fee]: <p>{item?.fee_amount}</p>,
    };
  });
  return updatedList;
};

const CommonTable = (props) => {
  const dispatch = useDispatch();
  const { currentMarketId } = useSelector((state) => state.commonApiData);
  const { marketPairList } = useSelector((state) => state.transactionHistories);
  const { filter } = useSelector((state) => state.exchange);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [filterObj, setFilterObj] = useState({});
  const { list, column, total, currentTab } = props;

  const hitApi = (tabName, data) => {
    setPage(data.page);
    setLimit(data.limit);
    let sendData = {
      page: page,
      limit: limit,
      ...filterObj,
      ...data,
    };
    switch (tabName) {
      case buy:
        sendData["transak_type"] = "BUY";
        dispatch(fetchBuySellHistory({ ...sendData }));
        break;
      case sell:
        sendData["transak_type"] = "SELL";
        dispatch(fetchBuySellHistory({ ...sendData }));
        break;
      case send:
        dispatch(getWithdrawHistory({ ...sendData }));
        break;
      case receive:
        dispatch(getDepositHistory({ ...sendData }));
        break;
      case trades:
        if (!marketPairList.length) return;
        const pairList = createPairList(marketPairList);
        let toSendMarket = currentMarketId
          ? currentMarketId
          : pairList[0].value;
        dispatch(getTransHisTrades({
          ...sendData, market: Object.keys(filter).length > 0 ?
            filter.market :
            toSendMarket
        }));
        break;
      case transfer:
        dispatch(
          getBalanceHistory({
            ...sendData,
            reference_type: "Account",
          })
        );
        break;
      default:
    }
  };

  const callBackFun = (obj) => {
    const { page, limit, ...rest } = obj;
    setPage(page);
    setLimit(limit);
    setFilterObj({ ...rest });
  };

  return (
    <>
      {props.isFilterVisible && (
        <Filter currentTab={currentTab} cbFun={callBackFun} />
      )}
      {props.isExportVisible && <ExportData currentTab={currentTab} />}
      <Table
        dataSource={list}
        columns={column}
        pagination={false}
        className="portfolioTable"
        rowKey={(obj) => obj.keyId}
      />
      <CustomPagination
        pageNo={page}
        limit={limit}
        total={total}
        onChange={(pageNo, pageSize) => {
          hitApi(currentTab, { page: pageNo, limit: pageSize });
        }}
      />
    </>
  );
};

export const Buy = (props) => {
  const { list, total } = useSelector((state) => state.buySell);

  return (
    <CommonTable
      list={buySellData(list, buy)}
      column={buySellColumns(buy)}
      total={total}
      currentTab={buy}
      isFilterVisible={props.isFilterVisible}
      isExportVisible={props.isExportVisible}
    />
  );
};

export const Sell = (props) => {
  const { list, total } = useSelector((state) => state.buySell);

  return (
    <CommonTable
      list={buySellData(list, sell)}
      column={buySellColumns(sell)}
      total={total}
      currentTab={sell}
      isFilterVisible={props.isFilterVisible}
      isExportVisible={props.isExportVisible}
    />
  );
};

export const Send = (props) => {
  const { withdrawHistory, withdrawHistoryTotal } = useSelector(
    (state) => state.transactionHistories
  );
  return (
    <CommonTable
      list={sendReceiveData(withdrawHistory, send)}
      column={sendReceiveColumns()}
      total={withdrawHistoryTotal}
      currentTab={send}
      isFilterVisible={props.isFilterVisible}
      isExportVisible={props.isExportVisible}
    />
  );
};

export const Receive = (props) => {
  const { depositHistory, depositHistoryTotal } = useSelector(
    (state) => state.transactionHistories
  );

  return (
    <CommonTable
      list={sendReceiveData(depositHistory, receive)}
      column={sendReceiveColumns()}
      total={depositHistoryTotal}
      currentTab={receive}
      isFilterVisible={props.isFilterVisible}
      isExportVisible={props.isExportVisible}
    />
  );
};

export const Trades = (props) => {
  const { tradeList, tradesCount } = useSelector((state) => state?.transactionHistories);
  return (
    <CommonTable
      list={tradesData(tradeList)}
      column={tradeColumns()}
      total={tradesCount}
      currentTab={trades}
      isFilterVisible={props.isFilterVisible}
      isExportVisible={props.isExportVisible}
    />
  );
};

export const TransferHistory = (props) => {
  const { balanceHistoryTotal, balanceHistory } = useSelector(
    (state) => state.commonApiData
  );

  return (
    <CommonTable
      list={transferData(balanceHistory)}
      column={transferColumns()}
      total={balanceHistoryTotal}
      currentTab={transfer}
      isFilterVisible={props.isFilterVisible}
      isExportVisible={props.isExportVisible}
    />
  );
};
