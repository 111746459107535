import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import style from "../../AssetsTable/style.module.scss";
import { Tabs } from "antd";
import TabPane from "antd/lib/tabs/TabPane";
import LoginActivity from "./LoginActivity";
import SecurityActivity from "./SecurityActivity";

const AccountActivity = () => {
  const { t } = useTranslation();
  const [currentTab, setCurrentTab] = useState("login");

  const onChangeTab = (key) => {
    setCurrentTab(key);
  };

  return (
    <>
      <div className="activityDiv">
        <div className={`assetTable ${style.port}`}>
          <div className="topHeading">
            <h4>{t("account_activity_page.heading")}</h4>
            <p className="text-black">{t("account_activity_page.desc")}</p>
          </div>

          <Tabs
            className="tradetab"
            defaultActiveKey={currentTab}
            onChange={onChangeTab}
            activeKey={currentTab}
          >
            <TabPane tab="Login Activity" key={"login"}>
              {currentTab === "login" && <LoginActivity />}
            </TabPane>
            <TabPane tab="Security Activity" key={"security"}>
              {currentTab === "security" && <SecurityActivity />}
            </TabPane>
          </Tabs>
        </div>
      </div>
      {/* </div> */}
    </>
  );
};

export default AccountActivity;
