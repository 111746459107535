import React, { useEffect } from "react";
import { useTranslation, withTranslation } from "react-i18next";
import { toast, ToastContainer } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import "react-toastify/dist/ReactToastify.css";
import _ from "lodash";
import { message } from "antd";
import { showAlert } from "../redux/feature";
import { fetchLogout } from "../redux/services/logout";

function GoAlert({uid}) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const alert = useSelector((state) => state.alert);
  const alertProps = {
    message: useSelector((state) => state.alert.message),
    code: useSelector((state) => state.alert.code),
    type: useSelector((state) => state.alert.type),
  };

  // for Alert

  useEffect(() => {
    if (alertProps.message && alertProps.message.length) {
      goAlert();
    }
  }, [alert]);

  const goAlert = () => {
    let type = alertProps.type;
    _.forEach(alertProps.message, function (value) {
      if (value) {
        if (value[0] === "market.trade.not_permitted" || value[0] === "resource.user.no_activity") {
          return;
        }

        if (type === "error") {
          message.error(t(value));

          if (value[0] === "authz.invalid_session" || value[0] === "authz.csrf_token_mismatch") {
            setTimeout(() => {
              window.location.reload();
            }, 500);
          }
          if (value[0] === "authz.csrf_token_in_valid" && uid) {
            setTimeout(() => {
              dispatch(fetchLogout({uid}));
              window.location.reload();
            }, 500);
          }
        } else {
          message.success(t(value));
        }
        //empty messages state
        dispatch(
          showAlert({
            message: "",
            type: "",
            code: "",
          })
        );
      }
    });
  };

  // return <ToastContainer autoClose="2000" />;
}
export default withTranslation()(GoAlert);
