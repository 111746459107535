import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { Tabs } from "antd";
import CommonSelectField from "./CommonSelectField";
import PayWith from "./PayWith";
import { buySellTabsKey, openCloseDivs } from "../../../staticObjects";
import styles from "./style.module.scss";
import { useDispatch, useSelector } from "react-redux";
import {
  getBalance,
  getCurrencies,
  initValidateTrans,
} from "../../../redux/services";
import Identityverify from "../../Common/Identityverify";
import { fetchBuySellData } from "../../../redux/services/buySellServices";
import { setBuySellOpen, setBuySellTab, setTransStatus } from "../../../redux/feature";
import { multiple0BeforeDecimal, regex, uppercase } from "../../../helpers";
import CoinList from "./CoinList";
import FiatList from "./FiatList";
import NetworkList from "./NetworkList";
import TransakWidget from "./TransakWidget";
import { useTranslation } from "react-i18next";

const BuySellTab = ({ props, activeTab }) => {
  const { TabPane } = Tabs;
  const dispatch = useDispatch();
  const { data } = useSelector((state) => state.user);
  const { balanceList } = useSelector((state) => state.commonApiData);
  const { transStatus, loading, current_Tab, support_data } = useSelector(
    (state) => state.buySell
  );

  const { t } = useTranslation();
  const amountRef = useRef();

  const [amount, setAmount] = useState("");
  const [amountErr, setAmountErr] = useState("");
  const [selectedCoin, setSelectedCoin] = useState({});
  const [selectedFiat, setSelectedFiat] = useState({});
  const [selectedNetwork, setSelectedNetwork] = useState({});
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState({});
  const [paymentMethod, setPaymentMethod] = useState([]);
  const [transakWidget, setTransakWidget] = useState(false);

  const { coin, fiat, pay, network } = openCloseDivs;
  const { buy, sell } = buySellTabsKey;
  const initDivs = {
    coinDiv: false,
    fiatDiv: false,
    payDiv: false,
    networkdiv: false,
  };
  const [divStatus, setDivStatus] = useState(initDivs);

  useEffect(() => {
    dispatch(getCurrencies());
    dispatch(getBalance());

    return () => {
      dispatch(setBuySellTab("Buy"));
      resetFields();
      dispatch(setBuySellOpen(false))
    };
  }, []);

  const selectedCurrBalObj = useMemo(() => {
    let data =
      balanceList.length &&
      balanceList.find(
        (val) => uppercase(val.currency) == selectedCoin?.currency
      );
    return data;
  }, [balanceList, selectedCoin]);

  let balObj = useMemo(() => {
    let data = {};
    data["balance"] = selectedCurrBalObj?.balance || 0.0;
    data["locked"] = selectedCurrBalObj?.locked || 0.0;
    data["p2p_balance"] = selectedCurrBalObj?.p2p_balance || 0.0;
    data["escrow_balance"] = selectedCurrBalObj?.escrow_balance || 0.0;
    data["currency_usdt"] = selectedCurrBalObj?.currency_usdt || 0.0;
    return data;
  }, [selectedCurrBalObj]);

  const { balance, currency_usdt } = balObj;

  const isBuyTab = current_Tab == "Buy";

  const fetchDataApi = useCallback(async (current_Tab) => {
    const res = await dispatch(fetchBuySellData({ transak_type: current_Tab }));
  });

  useEffect(() => {
    if (current_Tab) fetchDataApi(current_Tab);
  }, [current_Tab]);

  const fiatList = useMemo(() => {
    let data = support_data.filter((val) => val.type === fiat);
    return data;
  }, [support_data]);

  const coinList = useMemo(() => {
    let data = support_data.filter((val) => val.type === coin);
    return data;
  }, [support_data]);

  const networkList = useMemo(() => {
    if (coinList.length) return selectedCoin?.network_info;
  }, [selectedCoin]);

  useEffect(() => {
    if (networkList) setSelectedNetwork(networkList[0]);
  }, [networkList]);

  useEffect(() => {
    if (paymentMethod.length) setSelectedPaymentMethod(paymentMethod[0]);
  }, [paymentMethod]);
  useEffect(() => {
    if (Object.keys(selectedFiat).length > 0) {
      setPaymentMethod(selectedFiat.payment_details);
    }
  }, [selectedFiat]);

  useEffect(() => {
    if (coinList.length) setSelectedCoin(coinList[0]);
  }, [coinList]);

  useEffect(() => {
    if (fiatList.length) setSelectedFiat(fiatList[0]);
  }, [fiatList]);

  const openCloseDivsFun = (status, type = "") => {
    let tempDiv = { ...divStatus };
    if (!status) {
      setDivStatus(initDivs);
    } else {
      if (type === coin) {
        tempDiv["coinDiv"] = true;
      } else if (type === pay) {
        tempDiv["payDiv"] = true;
      } else if (type === fiat) {
        tempDiv["fiatDiv"] = true;
      } else if (type === network) {
        tempDiv["networkdiv"] = true;
      }
      setDivStatus({ ...tempDiv });
    }
  };

  const handleValidation = (data) => {
    let isValidated = true;
    let copyerror = amountErr;
    for (let key in data) {
      let value = data[key].toString().trim();
      switch (key) {
        case "amount":
          if (!parseFloat(value)) {
            copyerror = "Please enter a valid amount";
            isValidated = false;
          } else if (parseFloat(balance) == 0.0 && !isBuyTab) {
            copyerror = `You don't have sufficient balance`;
            isValidated = false;
          } else if (value > parseFloat(balance) && !isBuyTab) {
            copyerror = `Amount must be less than or equals to ${balance}`;
            isValidated = false;
          } else {
            copyerror = "";
          }
          break;
        default:
      }
    }
    setAmountErr(copyerror);
    return isValidated;
  };

  const handleChange = (e) => {
    let entered_val = e.target.value;
    let name = e.target.name;
    const amountRegex = regex(selectedCoin?.currency_precision, 10);
    if (name === "amount") {
      entered_val = multiple0BeforeDecimal(entered_val);
    }

    if (entered_val === "" || amountRegex.test(entered_val)) {
      handleValidation({ [name]: entered_val });
      setAmount(entered_val);
    }
  };

  const isLevel3Return = (compo) => {
    let isLevel3 = data?.level === 3;
    return isLevel3 ? compo : <Identityverify />;
  };

  const onChangeTab = (val) => {
    setAmountErr("");
    setAmount("");
    dispatch(setBuySellTab(val));
  };

  const resetFields = () => {
    setAmountErr("");
    setAmount("");
    if (coinList) setSelectedCoin(coinList[0]);
    if (fiatList) setSelectedFiat(fiatList[0]);
    if (networkList) setSelectedNetwork(networkList[0]);
    if (paymentMethod) setSelectedPaymentMethod(paymentMethod[0]);
  };

  const cbFun = (obj, type) => {
    // setAmount("");
    setAmountErr("");
    switch (type) {
      case coin:
        setSelectedCoin({ ...obj });
        openCloseDivsFun(false, coin);
        break;

      case fiat:
        setSelectedFiat({ ...obj });
        openCloseDivsFun(false, fiat);

        break;

      case pay:
        setSelectedPaymentMethod({ ...obj });
        openCloseDivsFun(false, pay);

        break;

      case network:
        setSelectedNetwork({ ...obj });
        openCloseDivsFun(false, network);

        break;

      default:
        break;
    }
  };

  const transakData = {
    cryptCur: selectedCoin?.currency,
    fiatCur: selectedFiat?.currency,
    amount: amount ? amount : "",
    type: current_Tab,
    paymentMethod: selectedPaymentMethod?.id,
    address: selectedNetwork?.address,
    network: selectedNetwork?.transak_network,
    userData: {
      firstName: data?.profiles && data?.profiles[0]?.first_name,
      lastName: data?.profiles && data?.profiles[0]?.last_name,
    },
  };

  let validateData = {
    fiatCurrency: selectedFiat?.currency,
    cryptoCurrency: selectedCoin?.currency,
    network: selectedNetwork?.transak_network,
    paymentMethod: selectedPaymentMethod?.id,
    fiatAmount: isBuyTab ? (amount.endsWith(".") ? amount + "0" : amount) : "",
    cryptoAmount: !isBuyTab
      ? amount.endsWith(".")
        ? amount + "0"
        : amount
      : "",
    isBuyOrSell: isBuyTab ? "BUY" : "SELL",
    t: t,
  };

  const onSubmit = async (e) => {
    e.preventDefault();

    if (handleValidation({ amount: amount })) {
      dispatch(initValidateTrans(validateData));
    }
  };

  const renderTabPane = () =>
    isLevel3Return(
      <CommonSelectField
        loading={loading}
        onClickCoin={() => openCloseDivsFun(true, coin)}
        onClickPay={() => openCloseDivsFun(true, pay)}
        onClickFiat={() => openCloseDivsFun(true, fiat)}
        onClickNetwork={() => openCloseDivsFun(true, network)}
        current_Tab={current_Tab}
        amount={amount}
        onChange={handleChange}
        selectedCoin={selectedCoin}
        selectedFiat={selectedFiat}
        selectedNetwork={selectedNetwork}
        selectedPaymentMethod={selectedPaymentMethod}
        selectedCurrBalObj={selectedCurrBalObj}
        amountErr={amountErr}
        balance={balance}
        isBuyTab={isBuyTab}
        currency_usdt={currency_usdt}
        onClick={onSubmit}
        amountRef={amountRef}
      />
    );

  useEffect(() => {
    if (transStatus) {
      setTransakWidget(true);
    } else {
      setTransakWidget(false);
    }
  }, [transStatus]);

  const { coinDiv, payDiv, fiatDiv, networkdiv } = divStatus;

  return (
    <div>
      <Tabs
        className={`buySellTab ${!isBuyTab && "sellSelectedTab"}`}
        defaultActiveKey={current_Tab}
        onChange={onChangeTab}
      >
        <TabPane tab="Buy Crypto" key={buy} className="">
          {renderTabPane()}
        </TabPane>
        <TabPane tab="Sell Crypto" key={sell} className="redSell">
          {renderTabPane()}
        </TabPane>
      </Tabs>

      {coinDiv && (
        <div className={styles.positionedContent}>
          <CoinList
            onClick={() => openCloseDivsFun(false)}
            coinList={coinList}
            cbFun={cbFun}
            isBuyTab={isBuyTab}
          />
        </div>
      )}
      {payDiv && (
        <div className={styles.positionedContent}>
          <PayWith
            onClick={() => openCloseDivsFun(false)}
            paymentMethod={paymentMethod}
            cbFun={cbFun}
          />
        </div>
      )}

      {fiatDiv && (
        <div className={`redrum ${styles.positionedContent}`} >
          <FiatList
            onClick={() => openCloseDivsFun(false)}
            fiatList={fiatList}
            cbFun={cbFun}
            isBuyTab={isBuyTab}

          />
        </div>
      )}

      {networkdiv && (
        <div className={styles.positionedContent}>
          <NetworkList
            onClick={() => openCloseDivsFun(false)}
            networkList={networkList}
            cbFun={cbFun}
          />
        </div>
      )}

      {transakWidget && (
        <TransakWidget
          data={transakData}
          open={transakWidget}
          resetFields={resetFields}
        />
      )}
    </div>
  );
};

export default BuySellTab;
