import { createAsyncThunk } from "@reduxjs/toolkit";
import { API } from "../../api";
import { buildQueryString } from "../../helpers";
import * as config from "../../utils/apiVersion";
import { startLoading, stopLoading } from "../feature";
import { errorToastSelector } from "../selector";

export const getNotifications = createAsyncThunk(
  "notification/getNotifications",
  async (payload, { rejectWithValue, dispatch }) => {
    try {
      dispatch(startLoading());
      let url = `/notification?${buildQueryString(payload)}`;
      const { data, headers } = await API.get(config.p2pWithHeader)(url);
      dispatch(stopLoading());
      return { list: data, total: headers?.total };
    } catch (e) {
      if (e.code !== 401) {
        dispatch(stopLoading());
        dispatch(errorToastSelector(e));
      }
      return rejectWithValue(e);
    }
  }
);

export const readNotification = createAsyncThunk(
  "notification/readNotification",
  async (payload, { dispatch }) => {
    try {
      let url = `/notification/action?${buildQueryString(payload)}`;
      const res = await API.put(config.p2p)(url);
      return { state: res.state, status: true };
    } catch (e) {
      if (e.message[0] !== "notification.cannot_read") {
        dispatch(errorToastSelector(e));
      }
      return false;
    }
  }
);
