import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Tabs, Button, Row, Col } from "antd";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { StoreImages } from "../../Storeimgaes/StoreImages";
import BuySellTab from "../CustomTabs/index.jsx";
import Points from "./Points";
import { getCurrencyDetails, getGraphData } from "../../../redux/services";
import PrimaryBalanceTab from "./PrimaryBalanceTab";
import { formatNumber } from "../../../helpers/formatNumber";
import style from "./style.module.scss";
import {
  coinDetailTabs,
  graphDurationTimeObj,
  upDownPriceColor,
} from "../../../staticObjects";
import TradeLineGraph from "../../Common/TradeLineGraph";
import { truncateData, truncateData2 } from "../../../helpers/truncate.js";
import { uppercase } from "../../../helpers/uppercase.js";
import i18next from "i18next";
import { graphTimeFrame } from "../../../redux/feature/index.js";

const CoinDetail = () => {
  const gutter = {
    xxl: 20,
    lg: 10,
    md: 10,
  };
  const { TabPane } = Tabs;
  const { t } = useTranslation();
  const { doc, web } = StoreImages;
  const dispatch = useDispatch();
  const { id } = useParams();
  const currDetail = useSelector(
    (state) => state.commonApiData?.currencyDetails
  );

  const currList = useSelector(
    (state) => state.commonApiData?.currenciesList
  );
  const { currencyGraphData } = useSelector((state) => state.commonApiData);
  const { overview, primaryBalance } = coinDetailTabs;
  const [activeTab, setActiveTab] = useState(overview);
  const [time, setTime] = useState(graphDurationTimeObj[0].value);
  const { timeFrame } = useSelector(
    (state) => state.commonApiData
  );
  useEffect(() => {
    if (id) {
      dispatch(getCurrencyDetails({ id }));
      graphApi(time);
    }
    return () => {
      dispatch(graphTimeFrame("hr"));
    };
  }, []);

  const graphApi = (time) => {
    setTime(time);
    dispatch(getGraphData({ id: id, time: time }));
    dispatch(graphTimeFrame(time));
  };

  if (currDetail?.length === 0 || Object.keys(currDetail).length === 0) return;

  const {
    icon_url,
    name,
    symbol,
    price,
    market_cap,
    volume_24h,
    volume_change_24h,
    circulating_supply,
    max_supply,
    cmc_rank,
    description,
    whitepaper,
    official_link,
    color,
    precision,
    cointdetailPage
  } = currDetail;

  const getkey = (time) => {
    let res = graphDurationTimeObj.find((item, idx) => item.value === time).key;
    return res ?? "";
  };
  const percentVal = currDetail[getkey(time)];
  const { up, down } = upDownPriceColor;

  return (
    <>
      <div className={`blueesback ${style.cointdetailPage}
        `}>
        <div className={style.cointdetailPage_div}>
          <div className={style.cointdetailPage_tabs}>
            <div className={style.cointdetailPage_headBar}>
              <h3 className={`text-black-36 ${style.heading} `}>
                <img className="coin_icon" src={icon_url} alt="icon" />
                {name}
                <span>{symbol}</span>
              </h3>
            </div>
            {/* <Row gutter={[40, 40]}> */}
            {/* <Col xs={24} sm={24} md={24} xl={16}> */}
            <Tabs
              className="line-none blueTabs"
              defaultActiveKey={activeTab}
              onChange={(key) => setActiveTab(key)}
            >
              <TabPane
                tab={t("coinDetail.overview")}
                key={overview}
              // className={style.paddingLeft}
              >
                {activeTab === overview && (
                  <div
                    className={`${style.cointdetailPage_tabs_overView} ${style.mt}`}
                  >
                    <Row>
                      <Col xl={24} md={24} xs={24}>
                        <div
                          className={`${style.cointdetailPage_tabs_overView_graphsec} card`}
                        >
                          <section>
                            <Row
                              className={
                                style.cointdetailPage_tabs_overView_graphsec_duration
                              }
                            >
                              <Col>
                                <h3>
                                  ${truncateData(price, 6)}
                                  <sup
                                    style={{
                                      color: percentVal > 0 ? up : down,
                                      top: "-1em",
                                    }}
                                  >
                                    {`${percentVal > 0 ? "+" : ""}${(
                                      percentVal ?? 0
                                    ).toFixed(2)}% `}
                                  </sup>
                                </h3>
                              </Col>
                              <Col>
                                <div className={style.btngraph}>
                                  {graphDurationTimeObj?.map(
                                    (item, idx) => {
                                      return (
                                        <div>
                                          <button
                                            key={item.key}
                                            className={`${style.timeBtn} ${time === item.value
                                              ? style.timeActive
                                              : ""
                                              }`}
                                            onClick={() =>
                                              graphApi(item?.value)
                                            }
                                          >
                                            {item.label}
                                          </button>
                                        </div>
                                      );
                                    }
                                  )}
                                </div>
                              </Col>
                            </Row>
                          </section>
                          <div className="scrollportfolio">
                            <section className={style.graph__section}>
                              <TradeLineGraph
                                data={currencyGraphData}
                                color={color}
                                graphTime={timeFrame}
                              />
                            </section>
                            <section
                              className={
                                style.cointdetailPage_tabs_overView_graphsec_points
                              }
                            >
                              <h4>{t("coinDetail.market_Stats")}</h4>
                              <Row gutter={[16, { xs: 8, md: 20, lg: 24 }]}>
                                <Col xxl={6} lg={8} md={12} xs={24}>
                                  <Points
                                    Heading={"Market cap"}
                                    Content={`$${formatNumber(market_cap)} `}
                                    tooltip={t("overivew.Market_cap")}
                                  />
                                </Col>
                                <Col xxl={6} lg={8} md={12} xs={24}>
                                  <Points
                                    Heading="Volume (24h)"
                                    Content={`$${formatNumber(
                                      volume_24h ?? 0
                                    )}`}
                                    detail={`${volume_change_24h > 0 ? "+" : ""
                                      }${(volume_change_24h ?? 0).toFixed(
                                        1
                                      )}% `}
                                    color={
                                      volume_change_24h > 0
                                        ? "green"
                                        : "orange"
                                    }
                                    tooltip={t("overivew.Volume_(24h)")}
                                  />
                                </Col>
                                <Col xxl={6} lg={8} md={12} xs={24}>
                                  <Points
                                    Heading="Circulating supply"
                                    Content={`${formatNumber(
                                      circulating_supply
                                    )} ${name}`}
                                    detail={
                                      max_supply
                                        ? `${(
                                          (Number(circulating_supply) /
                                            Number(max_supply)) *
                                          100
                                        ).toFixed()}% of total supply`
                                        : ""
                                    }
                                    color="grey"
                                    tooltip={t("overivew.Circulating_supply")}
                                  />
                                </Col>
                                <Col xxl={6} lg={8} md={12} xs={24}>
                                  <Points
                                    Heading="Popularity"
                                    Content={`# ${cmc_rank}`}
                                    tooltip={t("overivew.Popularity")}
                                  />
                                </Col>
                              </Row>
                            </section>
                            <section
                              className={
                                style.cointdetailPage_tabs_overView_graphsec_textline
                              }
                            >
                              <h4>{t("coinDetail.overview")}</h4>
                              {/* <p>{description}</p> */}
                              {i18next.exists("data." + id + ".desc") && <p>{t("data." + id + ".desc")}</p>}

                              <h5>{t("coinDetail.resources")}</h5>
                              {whitepaper && (
                                <a target="_blank" style={{ color: "##1ea0ab !important" }} href={whitepaper}>
                                  <img src={doc} alt="icon" />{" "}
                                  <span>{t("coinDetail.whitepaper")}</span>
                                </a>
                              )}
                              {official_link && (
                                <a target="_blank" href={official_link}>
                                  <img src={web} alt="icon" />
                                  <span>
                                    {t("coinDetail.official_Website")}
                                  </span>
                                </a>
                              )}
                              <br />

                              {
                                [...Array(8).keys()].map(index => {
                                  const questionKey = `qs${index + 1}`;
                                  const answerKey = `an${index + 1}`;
                                  if (i18next.exists(`data.${id}.${questionKey}`)) {
                                    return (
                                      <div key={index} style={{
                                        whiteSpace: "pre-line"
                                      }}>
                                        <h4>{t(`data.${id}.${questionKey}`)}</h4>
                                        <p>{t(`data.${id}.${answerKey}`)}</p>
                                      </div>
                                    );
                                  }
                                  return null;
                                })
                              }


                            </section>
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </div>
                )}
              </TabPane>
              <TabPane
                tab={t("coinDetail.primary_alance")}
                key={primaryBalance}
                className={style.paddingLeft}
              >
                {activeTab === primaryBalance && <PrimaryBalanceTab />}
              </TabPane>
            </Tabs>
            {/* </Col> */}
            {/* <Col xs={24} sm={24} md={24} xl={8} className="buySellInnerMargin">
                <div className={`${style.cointdetailPage_tabs_cardtab} card`}>
                  <div className="swapPage">
                    <BuySellTab fromWhere="overview" />
                  </div>
                </div>
              </Col>
            </Row> */}
          </div>
        </div>
      </div >
    </>
  );
};

export default CoinDetail;
