import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { sendTransakData } from "../../../redux/services";
import { setTransStatus } from "../../../redux/feature";
import transakSDK from "@transak/transak-sdk";
import { uppercase } from "../../../helpers";

const TransakWidget = (props) => {
  const dispatch = useDispatch();
  const { code, status } = useSelector((state) => state.transakWidget);

  const {
    cryptCur,
    fiatCur,
    amount,
    type,
    network,
    address,
    userData,
    paymentMethod,
  } = props?.data;

  const { open } = props;
  const [apiCall, setApiCall] = useState(false);
  const [transData, setTransdata] = useState({});

  useEffect(() => {
    if (apiCall) {
      dispatch(sendTransakData(transData));
    }
  }, [apiCall]);

  const transakConfig = {
    apiKey: "267e6370-f2f8-4f1b-8c1d-e4eb08474bca",
    environment: "PRODUCTION",
    cryptoCurrencyCode: cryptCur,
    defaultCryptoCurrency: cryptCur,
    network,
    fiatCurrency: fiatCur,
    redirectURL: "/buysell",
    widgetHeight: "500px",
    widgetWidth: "400px",
    themeColor: type === "Buy" ? "#1ea0ab" : "#ef4b31",
    productsAvailed: uppercase(type),
    paymentMethod: paymentMethod,
    disableWalletAddressForm: true,
    userData: userData,
  };

  if (type == "Buy") {
    transakConfig["walletAddress"] = address
    transakConfig["defaultFiatAmount"] = amount
    transakConfig["fiatAmount"] = amount
  }else{
    transakConfig["defaultCryptoAmount"] = amount
    transakConfig["cryptoAmount"] = amount
  }

  let transak = new transakSDK(transakConfig);

  // To get all the events
  transak.on(transak.ALL_EVENTS, (data) => {
    console.log(data, "::ALL_EVENTS");
  });

  transak.on(transak.EVENTS.TRANSAK_WIDGET_CLOSE, () => {
    dispatch(setTransStatus(false));
    console.log("::TRANSAK_WIDGET_CLOSE");
    props.resetFields();

    document.body.classList.remove("transak_widget");
  });

  transak.on(transak.EVENTS.TRANSAK_ORDER_CREATED, (orderData) => {
    console.log(orderData, "::ORDER_DATA_CREATED");
  });

  transak.on(transak.EVENTS.TRANSAK_ORDER_SUCCESSFUL, (orderData) => {
    let {
      userId,
      network,
      walletAddress,
      paymentOptionId,
      fiatCurrency,
      cryptoCurrency,
      fiatAmount,
      isBuyOrSell,
      totalFeeInFiat,
      cryptoAmount,
      status,
      id,
      conversionPrice,
      cryptoPaymentData,
    } = orderData?.status;

    let transData = {
      user_Id: userId,
      merchant_id: id,
      address:
        isBuyOrSell === "BUY" ? walletAddress : cryptoPaymentData?.address,
      network: network,
      payment_method: paymentOptionId,
      fiat_currency: fiatCurrency,
      crypto_currency: cryptoCurrency,
      fiat_amount: fiatAmount,
      transak_type: isBuyOrSell,
      fee_in_fiat: totalFeeInFiat,
      crypto_amount: cryptoAmount,
      status: status,
      conversion_price: conversionPrice,
      currency: cryptCur,
    };
    setTransdata(transData);
    setApiCall(true);
  });

  useEffect(() => {
    if (code === 201) {
      transak.close();
      props.resetFields();
    }
  }, [code]);

  useEffect(() => {
    if (!status) {
      transak.close();
      props.resetFields();
    }
  }, [status]);

  useEffect(() => {
    if (open) transak.init();
  }, [open]);

  useEffect(() => {
    document.body.classList.add("transak_widget");
    return () => {
      dispatch(setTransStatus(false));
    };
  }, []);
  return <></>;
};

export default TransakWidget;
