import { message } from "antd";
import { setBuySellOpen } from "../redux/feature";
export const checkProfile = (data, navigate, dispatch, closeModal) => {
  const profile = data.profiles[0];
  const { first_name, last_name, postcode, city, address } = profile;

  const isProfileIncomplete =
    !first_name || !last_name || !postcode || !city || !address;

  if (isProfileIncomplete) {
    message.error("Please update your profile");
    if (dispatch) dispatch(setBuySellOpen(false));
    if (closeModal) closeModal();
    navigate("/setting");
    return false;
  }

  return true;
};
