export const getToFixed = (num) => {
  return parseFloat(Number(num)?.toFixed(8));
};

export const getToFixedFiat = (num, curr) => {
  return parseFloat(Number(num)?.toFixed(curr === "crypto" ? 8 : 2));
};

export const showTwoDigits = (num) => {
  return Math.floor(num * 100) / 100;
};
export const getTwoFixed = (num) => {
  return parseFloat(Number(num)?.toFixed(2));
};

export const toOptionalFixed = (num, digits) => {
  return Number.parseFloat(Number(num).toFixed(digits));
};
