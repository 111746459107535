import { createSlice } from "@reduxjs/toolkit";
import { getOtpCode } from "../../services";

const initialState = {};

const settingSlice = createSlice({
  name: "setting",
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {},
});

export const {} = settingSlice.actions;

export default settingSlice.reducer;
