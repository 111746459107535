import React, { useEffect, useState } from "react";
import style from "./../Pages/CustomTabs/style.module.scss";
import { useSelector } from "react-redux";

import { StoreImages } from "../Storeimgaes/StoreImages";
import CustomInput from "../Ui/customInput/CustomInput";

const HeadComman = (props) => {
  const { isLight } = useSelector((state) => state.theme);
  const { data } = useSelector((state) => state.user);
  const [checkMode, setCheckMode] = useState(false)

  useEffect(() => {
    if (data?.mode === "dark") {
      setCheckMode(false)
    }
    else {
      setCheckMode(true)
    }
  }, [data])

  const { backArrow, WhiteBackArrow } = StoreImages;
  const { onClick, title, withSearch, query, setQuery } = props;
  return (
    <>
      <div className={style.btnList_heading}>
        <div className={style.btnList_top}>
          <button onClick={onClick} className="curserPointer">
            {checkMode ? (
              <img src={backArrow} alt="icon" />
            ) : (
              <img src={WhiteBackArrow} alt="imgs" />
            )}
          </button>
          <h5>{title}</h5>


        </div>
        {withSearch && <div className={style.Asset_body_search}>
          <CustomInput
            placeholder="Search"
            value={query}
            onChange={(event) => setQuery(event.target.value)}
          />
        </div>}
      </div>
    </>
  );
};

export default HeadComman;
